import { Listbox, Transition } from "@headlessui/react";
import { Fragment } from "react";

const content = {
    header: 'Date',
    options: [
        { value: 'created_on_ASC', label: 'From newest' },
        { value: 'created_on_DESC', label: 'From latest' },

    ],
};

export const DateFilter = ({ handleSortSelect }: { handleSortSelect: (option: 'created_on_DESC' | 'created_on_ASC') => void }) => {

    return (
        <Listbox as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <div>
                        <Listbox.Button
                            className="inline-flex justify-center gap-x-2.5 text-font16 font-medium leading-leading272 tracking-small">
                            {content.header}
                            <span className="ml-1 flex h-7 items-center">
                                {open ? (
                                    <div className="w-0 h-0 border-x-transparent border-x-4 border-b-text border-b-[7px]" />
                                ) : (
                                    <div className="w-0 h-0 border-x-transparent border-x-4 border-t-text border-t-[7px]" />
                                )}
                            </span>
                        </Listbox.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Listbox.Options
                            className="border border-black absolute p-1 left-0 z-10 mt-2 w-40 md:w-56 origin-top-right rounded-md bg-background cursor-pointer">
                            {content.options?.map((menuItem, index) => (
                                <Listbox.Option
                                    key={index}
                                    className="hover:text-active"
                                    value={menuItem.value}
                                    onClick={() => handleSortSelect(menuItem.value as 'created_on_DESC' | 'created_on_ASC')}
                                >
                                    {menuItem.label}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </>
            )}
        </Listbox>
    );
};