import { Link } from "react-router-dom";

export const DemoButton = ({ content }: { content: string }) => {
    return (
        <Link
            to={'/contact'}
            className="whitespace-nowrap bg-text h-10 md:h-[45px] rounded-lg w-[121px] md:w-fit text-center text-white py-3 px-5 md:px-3 md:px-5 text-xs md:text-font16 font-semibold md:leading-leading208 duration-500 hover:bg-active cursor-pointer mr-2 md:mr-0"
        >
            {content}
        </Link>
    );
}