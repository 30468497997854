import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'

export const Accordion = ({ faqList }: { faqList: { title: string, content: string | JSX.Element }[] }) => {
    return (
        <div className="mx-auto px-5 md:px-1 md:w-3/4 md:w-2/3 pt-7 md:pt-10 pb-pcustom60 md:pb-pcustom120">
            <dl>
                {faqList.map((faq, i) => (
                    <Disclosure as="div" key={i}
                        className="pb-6 last:pb-0">
                        {({ open }) => (
                            <>
                                <dt className={
                                    open ?
                                        "bg-whitespace rounded-t-2xl" :
                                        "bg-frameColor rounded-2xl hover:drop-shadow-boxshadow hover:text-active duration-500"}>
                                    <Disclosure.Button className="flex w-full justify-between p-7 md:px-10 md:pb-10 md:pt-pcustom42">
                                        <span
                                            className="text-font22 font-semibold leading-leading286 text-left">{faq.title}</span>
                                        <span className="ml-6 flex h-7 items-center">
                                            {open ? (
                                                <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                                            ) : (
                                                <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                                            )}
                                        </span>
                                    </Disclosure.Button>
                                </dt>
                                <Transition
                                    enter="transition duration-300 ease-linear"
                                    enterFrom="transform scale-y-100 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-100 ease-out"
                                    leaveFrom="transform scale-y-100 opacity-100"
                                    leaveTo="transform scale-100 opacity-0"
                                >
                                    <Disclosure.Panel as="dd"
                                        className="bg-whitespace px-7 md:px-10 pb-7 md:pb-10 rounded-b-2xl">
                                        <div className="text-base font-normal leading-leading272 tracking-small">{faq.content}</div>
                                    </Disclosure.Panel>
                                </Transition>
                            </>
                        )}
                    </Disclosure>
                ))}
            </dl>
        </div>
    );
};