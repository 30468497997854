import { Outlet, RouterProvider, ScrollRestoration, createBrowserRouter } from 'react-router-dom';
import './App.css';
import { Footer } from './Footer/Footer';
import { Nav } from './Nav/Nav';

function App() {
    return (
        <>
            <Nav />
            <main>
                <Outlet />
            </main>
            <Footer />
            <ScrollRestoration />
        </>
    );
}

export default App;
