import { Link } from "react-router-dom"
import { Header } from "../shared/Header"
import { useTitle } from "react-use";

export const PrivacyPolicy = () => {
    useTitle('More Metaverse | Privacy Policy');

    return (
        <div className="max-w-off mx-auto mb-[60px]">
            <Header
                title='Privacy Policy'
                text='This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.'
            />
            <div className="w-full px-5 md:px-0 md:w-[794px] mx-auto">
            <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">Topics:</p>
                    <div className="mt-10 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">What data do we collect?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How do we collect your data?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How will we use your data?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How do we store your data?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Marketing</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">What are your data protection rights?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">What are cookies?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How do we use cookies?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">What types of cookies do we use?</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How to manage your cookies</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Privacy policies of other websites</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Changes to our privacy policy</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How to contact us</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">How to contact the appropriate authorities</span>
                    </div>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">What data do we collect?</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">More Metaverse PSA collects the following data:</p>
                    <div className="mt-10 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Personal identification information: name and email address</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">IP address and location information</span>
                    </div>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">How do we collect your data?</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">You directly provide More Metaverse PSA with most of the data we collect. We collect data and process data when you: </p>
                    <div className="mt-10 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Register online or place an order for any of our products or services or sign up for a newsletter or contact us</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Voluntarily complete a customer survey or provide feedback on any of our message boards or via email</span>
                    </div>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">How will we use your data?</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">More Metaverse collects your data so that we can: </p>
                    <div className="mt-10 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Email you with special offers on other products and services we think you might like</span>
                    </div>
                    <div className="mt-2 flex gap-4">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <path d="M16.2802 9.53039L11.0302 14.7804C10.884 14.9266 10.692 15.0001 10.5 15.0001C10.308 15.0001 10.116 14.9266 9.96977 14.7804C9.67652 14.4871 9.67652 14.0131 9.96977 13.7198L13.9395 9.75012H2.25C1.83525 9.75012 1.5 9.41487 1.5 9.00012C1.5 8.58537 1.83525 8.25012 2.25 8.25012H13.9395L9.96977 4.28039C9.67652 3.98714 9.67652 3.5131 9.96977 3.21985C10.263 2.9266 10.737 2.9266 11.0302 3.21985L16.2802 8.46985C16.5735 8.7631 16.5735 9.23714 16.2802 9.53039Z" fill="#080708" />
                            </svg>
                        </div>
                        <span className="-mt-[5px] text-[16px]/[27.2px] tracking-[0.16px]">Email you back in order to respond to any queries you have submitted through the contact form</span>
                    </div>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">If you agree, More Metaverse PSA will share your data with our partner companies so that they may offer you their products and services.</p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">How do we store your data?</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">More Metaverse PSA securely stores your data at encrypted private servers.<br />More Metaverse will keep your personal data for 6 months. Once this time period has expired, we will delete your data by 14 days.
                    </p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">Marketing</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">More Metaverse PSA would like to send you information about products and services of ours that we think you might like, as well as those of our partner companies.</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">If you have agreed to receive marketing, you may always opt out at a later date.<br />
                        You have the right at any time to stop More Metaverse PSA from contacting you for marketing purposes.<br />
                        If you no longer wish to be contacted for marketing purposes, please email us at info@more-metaverse.com.
                    </p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">What are your data protection rights?</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">More Metaverse PSA would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The right to access - You have the right to request More Metaverse PSA for copies of your personal data.</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The right to rectification - You have the right to request that More Metaverse PSA corrects any information you believe is inaccurate. You also have the right to request More Metaverse to complete information you believe is incomplete. </p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The right to erasure - You have the right to request that More Metaverse PSA erases your personal data, under certain conditions.</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The right to restrict processing - You have the right to request that More Metaverse PSA restricts the processing of your personal data, under certain conditions. </p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The right to object to processing - You have the right to object to More Metaverse PSA's processing of your personal data, under certain conditions. </p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The right to data portability - You have the right to request that More Metaverse PSA transfers the data that we have collected to another organization, or directly to you, under certain conditions.<br />
                        If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: info@more-metaverse.com<br />
                        Or write to us: More Metaverse PSA ul. RONDO ONZ 1, 00-124, Warsaw, Poland.
                    </p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">What are cookies?</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we do not collect information from you automatically through cookies or similar technology.</p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">How to manage cookies</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some  website features may not function as a result.</p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">Privacy policies of other websites</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">The more-metaverse.com website could contain links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">Changes to our privacy policy</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">More Metaverse PSA keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on September 10th 2023.</p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">How to contact us</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">If you have any questions about More Metaverse PSA's privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us.</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">Email us at: info@more-metaverse.com</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">Or write to us at: More Metaverse PSA ul. RONDO ONZ 1, 00-124, Warsaw, Poland.</p>
                </div>

                <div className="mt-[60px]">
                    <p className="text-[32px]/[41.6px] font-serif font-semibold">How to contact the appropriate authority</p>
                    <p className="mt-10 text-[18px]/[30.6px] tracking-[0.36px]">Should you wish to report a complaint or if you feel that More Metaverse has not addressed your concern in a satisfactory manner, you may contact the Information Commissioner's Office.<br />
                        Email: kancelaria@giodo.gov.pl<br />
                        Address: ul. Stawki 2 00-193 Warsaw, Poland
                    </p>
                </div>

                <div className="mt-[60px]">
                    <Link to='/'
                        className="text-font18 leading-leading234 flex items-center gap-2 py-5"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
                            <path d="M10.3289 12.3377C10.557 12.5658 10.557 12.9345 10.3289 13.1626C10.2151 13.2763 10.0658 13.3335 9.91647 13.3335C9.76714 13.3335 9.61782 13.2763 9.50407 13.1626L4.25407 7.91256C4.02599 7.68447 4.02599 7.31577 4.25407 7.08769L9.50407 1.83769C9.73215 1.60961 10.1008 1.60961 10.3289 1.83769C10.557 2.06577 10.557 2.43447 10.3289 2.66256L5.4913 7.50012L10.3289 12.3377Z" fill="#080708" />
                        </svg>
                        <span>To the Main Page</span>
                    </Link>
                </div>
            </div>
        </div>
    )
}
