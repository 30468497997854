import { useQuery } from "@apollo/client";
import { getHomePageBlogPosts } from "../queries/get-blogposts";
import { Link } from "react-router-dom";
import { Carousel } from "../shared/Carousel";
import { BlogPost } from "./BlogPost";

const BlogSectionContent = {
    header: 'Learn more about us and the Metaverse on our ',
    linkElement: 'Blog',
};

const slugs = ['why-do-we-plant-one-tree-for-every-nft-sold', 'powering-the-future-of-digital-art', 'metaverse-explained'];

export const BlogSection = () => {
    const { loading, error, data } = useQuery<{[key: string]: {_slug: string, title: string, category: string, cover_image: {url: string}}}>(getHomePageBlogPosts, {
        variables: { slug1: slugs[0], slug2: slugs[1], slug3: slugs[2] }
    });

    const blogPosts = [];
    if (data) {
        blogPosts.push(data.BlogPost1);
        blogPosts.push(data.BlogPost2);
        blogPosts.push(data.BlogPost3);
    }

    return (
        <div className="pt-pcustom60 md:pt-pcustom120 max-w-off mx-auto">
            <h2
                className="text-5xl md:text-technologies font-serif font-semibold leading:leading-leading624 md:leading-leading675 md:w-4/6 px-5 md:px-0">
                {BlogSectionContent.header}
                <Link 
                    to={'/blog'}
                    className="underline underline-offset-2">{BlogSectionContent.linkElement}
                </Link>.
            </h2>
            <section className="hidden md:inline-grid md:grid-cols-3 md:grid-row-1 md:gap-5 pt-pcustom70 md:pt-20 md:pb-16">
                {blogPosts.map((post, index) => {
                    return (
                        <BlogPost key={index} blogPost={post} />
                    )
                })}
            </section>
            <section className="md:hidden flex pb-12 md:pb-20 pt-pcustom70">
                <Carousel>
                    {blogPosts?.map((post, index) => (
                        <Link
                            key={index}
                            to={`/blog/${post?._slug}`}
                        >
                            <div>
                                <img
                                    src={post?.cover_image !== null ? post?.cover_image.url : "https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                                    alt={post?.title}
                                    className="aspect-[4/3] rounded-3xl bg-gray-100 object-cover" />
                                <div className="pt-5 md:pt-7">
                                    <div
                                        className="pb-3 text-sm leading-leading182">{post?.category}</div>
                                    <h2
                                        className="font-serif max-w-full font-semibold text-technologiesMobile leading-leading364 hover:text-active">{post?.title}</h2>
                                </div>
                            </div>
                        </Link>
                    ))}
                </Carousel>
            </section>
        </div>
    );
};