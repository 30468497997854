import { Link } from "react-router-dom";

export const Brand = () => {
    return (
        <>
            <Link to="/" className="hidden md:block">
                <svg width="144" height="48" viewBox="0 0 144 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M6.46853 38.3619C9.5055 38.3619 11.9675 35.8937 11.9675 32.8489C11.9675 29.8042 9.5055 27.3359 6.46853 27.3359C3.43156 27.3359 0.969604 29.8042 0.969604 32.8489C0.969604 35.8937 3.43156 38.3619 6.46853 38.3619Z"
                        fill="white" />
                    <path
                        d="M33.9888 27.2725C32.6236 27.2725 31.3605 27.769 30.4036 28.6093C27.6478 30.9266 28.0943 34.8863 26.2699 36.0321C25.0195 36.8215 23.0292 36.0703 20.8475 36.0067C20.6306 35.9812 20.4265 35.9685 20.1968 35.9685C17.1603 35.9685 14.6979 38.4385 14.6979 41.4815C14.6979 44.5245 17.1603 46.9945 20.1968 46.9945C21.0899 46.9945 21.9447 46.778 22.6847 46.3961C22.774 46.3579 22.8633 46.3197 22.9399 46.2687C23.0547 46.1924 23.1568 46.1287 23.2588 46.0523C24.0754 45.5048 24.7261 44.7536 25.1471 43.8624C26.3337 41.6979 26.4612 39.2279 27.7116 38.3748C29.7529 36.9871 33.4019 39.6735 36.8977 37.4709C36.936 37.4454 36.9615 37.4199 36.9998 37.3945C38.4925 36.4141 39.4749 34.7207 39.4749 32.7982C39.4877 29.7425 37.0253 27.2725 33.9888 27.2725Z"
                        fill="white" />
                    <path
                        d="M23.1695 11.319C23.2078 11.2935 23.2333 11.268 23.2716 11.2426C24.7643 10.2622 25.7467 8.56885 25.7467 6.6463C25.7467 3.60333 23.2843 1.1333 20.2478 1.1333H20.235H20.2223C18.4616 1.1333 16.9051 1.96089 15.8971 3.24683C13.9834 5.58953 14.162 8.86168 12.5416 9.89298C11.2913 10.6824 9.32649 9.93118 7.15754 9.86752C6.94065 9.84205 6.72375 9.82932 6.50686 9.82932C5.42238 9.82932 4.4017 10.1476 3.55964 10.6951C3.49585 10.7333 3.43205 10.7715 3.36826 10.8097C3.30447 10.8479 3.25344 10.8861 3.2024 10.937C1.86276 11.9429 1.00793 13.5344 1.00793 15.3423C1.00793 18.3853 3.47033 20.8553 6.50686 20.8553C7.45099 20.8553 8.33133 20.6134 9.1096 20.206C9.14787 20.1805 9.19891 20.1678 9.23718 20.1423C9.24994 20.1296 9.2627 20.1296 9.27546 20.1169C10.3217 19.5057 11.151 18.5635 11.6103 17.4304C12.6565 15.3423 12.8223 13.0505 14.0216 12.2357C16.012 10.8479 19.6737 13.5216 23.1695 11.319Z"
                        fill="white" />
                    <path
                        d="M39.4877 15.3421C39.4877 12.2991 37.0253 9.8291 33.9888 9.8291C32.5343 9.8291 31.2074 10.402 30.225 11.3188C27.6605 13.6487 28.0561 17.4556 26.2699 18.5888C25.0323 19.3654 23.0802 18.6397 20.924 18.5633C20.6816 18.5379 20.4392 18.5124 20.1968 18.5124C17.1603 18.5124 14.6979 20.9824 14.6979 24.0254C14.6979 27.0684 17.1603 29.5384 20.1968 29.5384C21.0771 29.5384 21.9064 29.3347 22.6464 28.9654C22.7485 28.9272 22.8506 28.889 22.9399 28.8254C23.1185 28.7108 23.2844 28.5962 23.4375 28.4689C24.1392 27.9469 24.7261 27.2721 25.1088 26.4827C26.3337 24.3055 26.4485 21.7845 27.7243 20.9188C29.3191 19.8365 31.9091 21.2371 34.6267 20.8424C37.357 20.5241 39.4749 18.1941 39.4749 15.3676C39.4749 15.3548 39.4749 15.3548 39.4749 15.3421C39.4877 15.3548 39.4877 15.3421 39.4877 15.3421Z"
                        fill="white" />
                    <path
                        d="M49.7838 37.3049V27.1192H51.8762V28.5324C52.0421 28.2778 52.259 28.0359 52.5397 27.8194C52.7821 27.6412 53.0883 27.4757 53.471 27.3356C53.841 27.1956 54.3258 27.1192 54.9 27.1192C55.4486 27.1192 55.9079 27.2083 56.2907 27.3738C56.6607 27.5521 56.9669 27.7431 57.1965 27.9468C57.4262 28.1632 57.592 28.3542 57.6941 28.5452C57.7962 28.7362 57.8472 28.8507 57.8472 28.8889C57.8472 28.9271 57.8982 28.8635 58.0003 28.6725C58.1024 28.4942 58.281 28.2905 58.5362 28.0614C58.7913 27.8322 59.1231 27.6157 59.5441 27.412C59.9651 27.2083 60.501 27.1064 61.1517 27.1064C61.8023 27.1064 62.3382 27.221 62.772 27.4502C63.193 27.6794 63.5375 27.9595 63.8054 28.2778C64.0606 28.5961 64.252 28.9144 64.354 29.2327C64.4689 29.551 64.5199 29.8057 64.5199 29.9839V37.3049H62.4275V30.9388C62.4275 30.7733 62.3765 30.595 62.2617 30.4168C62.1468 30.2385 62.0065 30.073 61.8406 29.933C61.662 29.7929 61.4706 29.6783 61.241 29.5765C61.0113 29.4746 60.7817 29.4364 60.5392 29.4364C59.8375 29.4364 59.2506 29.6401 58.8041 30.0476C58.3575 30.455 58.1279 31.0661 58.1279 31.881V37.3049H56.0355V30.977C56.0355 30.5823 55.8951 30.2258 55.6272 29.9075C55.3593 29.5892 54.9638 29.4237 54.4534 29.4237C54.109 29.4237 53.7772 29.4746 53.471 29.5765C53.1521 29.6783 52.8841 29.8311 52.6417 30.0348C52.3993 30.2385 52.2079 30.4932 52.0676 30.7988C51.9272 31.1043 51.8507 31.4608 51.8507 31.8682V37.3049H49.7838Z"
                        fill="white" />
                    <path
                        d="M71.1799 27.1191C74.7012 27.1191 75.9515 30.1239 75.6326 32.8486H68.3475C68.7813 35.8279 72.7874 35.6624 74.7012 34.3637V36.4772C65.2727 40.7425 62.772 27.1191 71.1799 27.1191ZM73.5657 31.1679C73.4636 28.0486 68.5388 28.2778 68.3475 31.1679H73.5657Z"
                        fill="white" />
                    <path
                        d="M83.2112 29.4619H80.9146V37.3049H78.7712V29.4619H77.1509V27.3738H78.7712V23.7961L80.9146 23.0449V27.3738H83.2112V29.4619Z"
                        fill="white" />
                    <path
                        d="M94.528 27.1191V37.2412H92.4611V35.8788C89.3863 38.9473 84.1936 36.7319 84.1936 32.2756C84.1936 27.8831 89.5139 25.5404 92.4611 28.4815V27.1191H94.528ZM89.348 35.4459C93.418 35.4332 93.418 29.169 89.348 29.1563C85.2526 29.1817 85.2526 35.4205 89.348 35.4459Z"
                        fill="white" />
                    <path d="M101.086 37.3048L95.9697 27.1191H98.4576L101.086 32.4794L103.727 27.1191H106.215L101.086 37.3048Z"
                        fill="white" />
                    <path
                        d="M118.067 27.1192H120.16V28.3797C120.338 28.1505 120.594 27.934 120.925 27.7431C121.206 27.5903 121.576 27.4502 122.035 27.3102C122.495 27.1701 123.069 27.1064 123.758 27.1064V29.1945C123.413 29.1945 123.03 29.2327 122.609 29.2964C122.188 29.36 121.793 29.4619 121.436 29.6147C121.066 29.7675 120.772 29.9584 120.53 30.2003C120.287 30.4423 120.16 30.7478 120.16 31.1043V37.3049H118.067V27.1192Z"
                        fill="white" />
                    <path
                        d="M128.504 27.1191C130.405 27.1191 130.928 27.4756 131.77 27.9595L130.762 29.3218C128.938 28.2268 127.139 28.7998 127.139 29.9584C127.139 31.499 132.217 31.117 132.217 33.8035C132.217 37.0756 127.968 38.6162 124.893 35.917L125.914 34.3637C127.19 35.7388 130.15 35.9298 130.15 34.0072C130.15 32.4666 125.072 33.0396 125.072 29.9584C125.072 28.9908 126.22 27.1191 128.504 27.1191Z"
                        fill="white" />
                    <path
                        d="M111.637 27.1191C115.159 27.1191 116.409 30.1239 116.09 32.8486H108.805C109.239 35.8279 113.245 35.6624 115.159 34.3637V36.4772C105.73 40.7425 103.217 27.1191 111.637 27.1191ZM114.023 31.1679C113.921 28.0486 108.996 28.2778 108.805 31.1679H114.023Z"
                        fill="white" />
                    <path
                        d="M138.456 27.1191C141.977 27.1191 143.227 30.1239 142.908 32.8486H135.623C136.057 35.8279 140.063 35.6624 141.977 34.3637V36.4772C132.536 40.7425 130.035 27.1191 138.456 27.1191ZM140.841 31.1679C140.739 28.0486 135.815 28.2778 135.623 31.1679H140.841Z"
                        fill="white" />
                    <path
                        d="M49.7838 21.008V10.8223H51.8762V12.2356C52.0421 11.9809 52.259 11.739 52.5397 11.5226C52.7821 11.3443 53.0883 11.1788 53.471 11.0387C53.841 10.886 54.3258 10.8223 54.9 10.8223C55.4486 10.8223 55.9079 10.9114 56.2907 11.0769C56.6607 11.2552 56.9669 11.4462 57.1965 11.6499C57.4262 11.8663 57.592 12.0573 57.6941 12.2483C57.7962 12.4393 57.8472 12.5539 57.8472 12.5921C57.8472 12.6303 57.8982 12.5666 58.0003 12.3756C58.1024 12.1974 58.281 11.9937 58.5362 11.7645C58.7913 11.5353 59.1231 11.3189 59.5441 11.1151C59.9651 10.9114 60.501 10.8096 61.1517 10.8096C61.8023 10.8096 62.3382 10.9242 62.772 11.1533C63.193 11.3825 63.5375 11.6626 63.8054 11.9809C64.0606 12.2992 64.252 12.6175 64.354 12.9358C64.4689 13.2541 64.5199 13.5088 64.5199 13.687V21.008H62.4275V14.6419C62.4275 14.4764 62.3765 14.2982 62.2617 14.1199C62.1468 13.9417 62.0065 13.7761 61.8406 13.6361C61.662 13.496 61.4706 13.3815 61.241 13.2796C61.0113 13.1777 60.7817 13.1395 60.5392 13.1395C59.8375 13.1395 59.2506 13.3433 58.8041 13.7507C58.3575 14.1581 58.1279 14.7693 58.1279 15.5841V21.008H56.0355V14.6801C56.0355 14.2854 55.8951 13.9289 55.6272 13.6106C55.3593 13.2923 54.9638 13.1268 54.4534 13.1268C54.109 13.1268 53.7772 13.1777 53.471 13.2796C53.1521 13.3815 52.8841 13.5342 52.6417 13.738C52.3993 13.9417 52.2079 14.1963 52.0676 14.5019C51.9272 14.8074 51.8507 15.1639 51.8507 15.5714V21.008H49.7838Z"
                        fill="white" />
                    <path
                        d="M71.2436 10.8223C71.9453 10.8223 72.6088 10.9623 73.2212 11.2297C73.8464 11.4971 74.3822 11.8663 74.8415 12.3247C75.3008 12.783 75.6708 13.3305 75.9388 13.9544C76.2067 14.5782 76.347 15.2403 76.347 15.9406C76.347 16.6408 76.2067 17.3029 75.9388 17.914C75.6708 18.5379 75.3008 19.0727 74.8415 19.531C74.3822 19.9894 73.8464 20.3586 73.2212 20.626C72.596 20.8934 71.9453 21.0334 71.2436 21.0334C70.5419 21.0334 69.8785 20.8934 69.266 20.626C68.6409 20.3586 68.105 19.9894 67.6329 19.531C67.1609 19.0727 66.8036 18.5379 66.5357 17.914C66.2678 17.2902 66.1274 16.6408 66.1274 15.9406C66.1274 15.2403 66.2678 14.5782 66.5357 13.9544C66.8036 13.3305 67.1736 12.783 67.6329 12.3247C68.105 11.8663 68.6409 11.4971 69.266 11.2297C69.8785 10.9623 70.5419 10.8223 71.2436 10.8223ZM71.2436 19.0217C71.6774 19.0217 72.0857 18.9453 72.4557 18.7798C72.8384 18.6143 73.1574 18.3979 73.4381 18.1178C73.7188 17.8377 73.9484 17.5066 74.1143 17.1374C74.2801 16.7682 74.3695 16.3607 74.3695 15.9278C74.3695 15.4949 74.2801 15.0875 74.1143 14.7183C73.9484 14.3491 73.7188 14.018 73.4381 13.7379C73.1574 13.4578 72.8257 13.2286 72.4557 13.0631C72.0729 12.8976 71.6774 12.8212 71.2436 12.8212C70.8098 12.8212 70.4016 12.9103 70.0316 13.0631C69.6488 13.2286 69.3298 13.4578 69.0491 13.7379C68.7685 14.018 68.5388 14.3491 68.3729 14.7183C68.2071 15.1002 68.1305 15.4949 68.1305 15.9278C68.1305 16.3607 68.2071 16.7682 68.3729 17.1374C68.5388 17.5193 68.7557 17.8377 69.0491 18.1178C69.3298 18.3979 69.6616 18.6143 70.0316 18.7798C70.4016 18.9453 70.8098 19.0217 71.2436 19.0217Z"
                        fill="white" />
                    <path
                        d="M78.1333 10.8223H80.2257V12.0828C80.4043 11.8536 80.6595 11.6372 80.9912 11.4462C81.2719 11.2934 81.6419 11.1533 82.1012 11.0133C82.5605 10.8732 83.1346 10.8096 83.8236 10.8096V12.8976C83.4791 12.8976 83.0964 12.9358 82.6753 12.9995C82.2543 13.0632 81.8588 13.165 81.5015 13.3178C81.1315 13.4706 80.8381 13.6616 80.5957 13.9035C80.3533 14.1454 80.2257 14.4509 80.2257 14.8074V21.008H78.1333V10.8223Z"
                        fill="white" />
                    <path
                        d="M90.0115 10.8223C93.5328 10.8223 94.7832 13.827 94.4642 16.5517H87.1791C87.6129 19.531 91.619 19.3655 93.5328 18.0668V20.1804C84.1043 24.4456 81.5908 10.8223 90.0115 10.8223ZM92.3973 14.8711C92.2952 11.7517 87.3704 11.9809 87.1791 14.8711H92.3973Z"
                        fill="white" />
                </svg>
            </Link>
            <Link to="/" className="md:hidden">
                <svg width="105" height="35" viewBox="0 0 105 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1298_2296)">
                        <path
                            d="M4.71666 27.9724C6.93112 27.9724 8.7263 26.1726 8.7263 23.9525C8.7263 21.7324 6.93112 19.9326 4.71666 19.9326C2.50221 19.9326 0.707031 21.7324 0.707031 23.9525C0.707031 26.1726 2.50221 27.9724 4.71666 27.9724Z"
                            fill="white" />
                        <path
                            d="M24.7834 19.8857C23.788 19.8857 22.867 20.2478 22.1693 20.8605C20.1598 22.5502 20.4854 25.4375 19.1551 26.273C18.2434 26.8486 16.7921 26.3009 15.2013 26.2544C15.0431 26.2359 14.8943 26.2266 14.7268 26.2266C12.5127 26.2266 10.7172 28.0277 10.7172 30.2465C10.7172 32.4653 12.5127 34.2664 14.7268 34.2664C15.378 34.2664 16.0013 34.1086 16.5409 33.83C16.606 33.8022 16.6711 33.7743 16.727 33.7372C16.8107 33.6815 16.8851 33.6351 16.9595 33.5794C17.5549 33.1802 18.0294 32.6324 18.3364 31.9826C19.2016 30.4043 19.2946 28.6032 20.2063 27.9812C21.6948 26.9693 24.3555 28.9282 26.9045 27.3221C26.9325 27.3035 26.9511 27.2849 26.979 27.2664C28.0674 26.5515 28.7838 25.3168 28.7838 23.9149C28.7931 21.6868 26.9976 19.8857 24.7834 19.8857Z"
                            fill="white" />
                        <path
                            d="M16.8944 8.25323C16.9223 8.23466 16.9409 8.21609 16.9688 8.19753C18.0573 7.48267 18.7736 6.24792 18.7736 4.84607C18.7736 2.62723 16.9781 0.826172 14.764 0.826172H14.7547H14.7454C13.4616 0.826172 12.3266 1.42962 11.5916 2.36729C10.1962 4.07551 10.3264 6.46145 9.14492 7.21344C8.23322 7.78904 6.80054 7.24129 5.21901 7.19487C5.06086 7.1763 4.90271 7.16702 4.74456 7.16702C3.95379 7.16702 3.20955 7.39912 2.59554 7.79832C2.54903 7.82617 2.50251 7.85402 2.456 7.88188C2.40948 7.90973 2.37227 7.93758 2.33506 7.97471C1.35823 8.70813 0.734924 9.86861 0.734924 11.1869C0.734924 13.4057 2.53042 15.2068 4.74456 15.2068C5.43299 15.2068 6.0749 15.0304 6.64239 14.7333C6.6703 14.7148 6.70751 14.7055 6.73542 14.6869C6.74472 14.6776 6.75402 14.6776 6.76333 14.6683C7.52618 14.2227 8.13088 13.5357 8.46579 12.7095C9.22865 11.1869 9.34959 9.51583 10.2241 8.92166C11.6754 7.90973 14.3453 9.85933 16.8944 8.25323Z"
                            fill="white" />
                        <path
                            d="M28.7931 11.1869C28.7931 8.96805 26.9976 7.16699 24.7834 7.16699C23.7229 7.16699 22.7554 7.58476 22.039 8.2532C20.1691 9.95214 20.4575 12.728 19.1551 13.5543C18.2527 14.1206 16.8293 13.5914 15.2571 13.5357C15.0803 13.5171 14.9036 13.4986 14.7268 13.4986C12.5127 13.4986 10.7172 15.2996 10.7172 17.5185C10.7172 19.7373 12.5127 21.5383 14.7268 21.5383C15.3687 21.5383 15.9734 21.3898 16.513 21.1206C16.5874 21.0927 16.6618 21.0649 16.727 21.0185C16.8572 20.9349 16.9781 20.8513 17.0898 20.7585C17.6015 20.3779 18.0294 19.8858 18.3085 19.3102C19.2016 17.7227 19.2853 15.8845 20.2156 15.2532C21.3785 14.4641 23.267 15.4853 25.2486 15.1975C27.2395 14.9654 28.7838 13.2665 28.7838 11.2055C28.7838 11.1962 28.7838 11.1962 28.7838 11.1869C28.7931 11.1962 28.7931 11.1869 28.7931 11.1869Z"
                            fill="white" />
                        <path
                            d="M36.3007 27.202V19.7749H37.8264V20.8054C37.9473 20.6197 38.1055 20.4433 38.3101 20.2855C38.4869 20.1555 38.7102 20.0349 38.9893 19.9327C39.259 19.8306 39.6126 19.7749 40.0312 19.7749C40.4312 19.7749 40.7661 19.8399 41.0452 19.9606C41.315 20.0906 41.5383 20.2298 41.7058 20.3784C41.8732 20.5362 41.9942 20.6754 42.0686 20.8147C42.143 20.954 42.1802 21.0375 42.1802 21.0654C42.1802 21.0932 42.2174 21.0468 42.2919 20.9075C42.3663 20.7776 42.4965 20.629 42.6826 20.4619C42.8686 20.2948 43.1105 20.137 43.4175 19.9884C43.7245 19.8399 44.1153 19.7656 44.5897 19.7656C45.0642 19.7656 45.4549 19.8492 45.7712 20.0163C46.0782 20.1834 46.3294 20.3876 46.5248 20.6197C46.7108 20.8518 46.8504 21.0839 46.9248 21.316C47.0085 21.5481 47.0457 21.7338 47.0457 21.8638V27.202H45.52V22.5601C45.52 22.4394 45.4828 22.3094 45.3991 22.1794C45.3154 22.0494 45.213 21.9288 45.0921 21.8266C44.9618 21.7245 44.8223 21.641 44.6548 21.5667C44.4874 21.4924 44.3199 21.4646 44.1432 21.4646C43.6315 21.4646 43.2036 21.6131 42.8779 21.9102C42.5523 22.2073 42.3849 22.6529 42.3849 23.2471V27.202H40.8592V22.5879C40.8592 22.3001 40.7568 22.0402 40.5615 21.8081C40.3661 21.576 40.0777 21.4553 39.7056 21.4553C39.4544 21.4553 39.2125 21.4924 38.9893 21.5667C38.7567 21.641 38.5613 21.7524 38.3846 21.9009C38.2078 22.0494 38.0682 22.2351 37.9659 22.4579C37.8636 22.6807 37.8078 22.9407 37.8078 23.2378V27.202H36.3007Z"
                            fill="white" />
                        <path
                            d="M51.9019 19.7744C54.4696 19.7744 55.3813 21.9654 55.1487 23.9521H49.8366C50.153 26.1245 53.0741 26.0039 54.4696 25.0569V26.598C47.5946 29.7081 45.7712 19.7744 51.9019 19.7744ZM53.6416 22.7267C53.5672 20.4521 49.9762 20.6192 49.8366 22.7267H53.6416Z"
                            fill="white" />
                        <path
                            d="M60.6747 21.4828H59.0001V27.2016H57.4372V21.4828H56.2557V19.9602H57.4372V17.3515L59.0001 16.8037V19.9602H60.6747V21.4828Z"
                            fill="white" />
                        <path
                            d="M68.9266 19.7744V27.1551H67.4195V26.1617C65.1775 28.3991 61.3911 26.7837 61.3911 23.5344C61.3911 20.3314 65.2705 18.6232 67.4195 20.7678V19.7744H68.9266ZM65.1496 25.846C68.1172 25.8367 68.1172 21.2691 65.1496 21.2598C62.1633 21.2784 62.1633 25.8275 65.1496 25.846Z"
                            fill="white" />
                        <path d="M73.7084 27.2015L69.9779 19.7744H71.792L73.7084 23.6829L75.6342 19.7744H77.4483L73.7084 27.2015Z"
                            fill="white" />
                        <path
                            d="M86.0908 19.7749H87.6165V20.694C87.7468 20.5269 87.9328 20.3691 88.1747 20.2298C88.3794 20.1184 88.6492 20.0163 88.9841 19.9142C89.319 19.812 89.7376 19.7656 90.24 19.7656V21.2882C89.9888 21.2882 89.7097 21.316 89.4027 21.3624C89.0957 21.4089 88.8073 21.4831 88.5468 21.5945C88.277 21.7059 88.0631 21.8452 87.8863 22.0216C87.7096 22.198 87.6165 22.4208 87.6165 22.6807V27.202H86.0908V19.7749Z"
                            fill="white" />
                        <path
                            d="M93.7008 19.7744C95.0869 19.7744 95.4684 20.0344 96.0824 20.3871L95.3474 21.3805C94.0171 20.5821 92.7053 20.9999 92.7053 21.8447C92.7053 22.968 96.408 22.6895 96.408 24.6484C96.408 27.0344 93.31 28.1577 91.068 26.1895L91.8122 25.0569C92.7425 26.0596 94.9009 26.1988 94.9009 24.797C94.9009 23.6736 91.1982 24.0914 91.1982 21.8447C91.1982 21.1391 92.0355 19.7744 93.7008 19.7744Z"
                            fill="white" />
                        <path
                            d="M81.402 19.7744C83.9697 19.7744 84.8814 21.9654 84.6488 23.9521H79.3368C79.6531 26.1245 82.5742 26.0039 83.9697 25.0569V26.598C77.0947 29.7081 75.262 19.7744 81.402 19.7744ZM83.1417 22.7267C83.0673 20.4521 79.4763 20.6192 79.3368 22.7267H83.1417Z"
                            fill="white" />
                        <path
                            d="M100.957 19.7744C103.525 19.7744 104.436 21.9654 104.204 23.9521H98.8918C99.2081 26.1245 102.129 26.0039 103.525 25.0569V26.598C96.6405 29.7081 94.8171 19.7744 100.957 19.7744ZM102.697 22.7267C102.622 20.4521 99.0314 20.6192 98.8918 22.7267H102.697Z"
                            fill="white" />
                        <path
                            d="M36.3007 15.3182V7.89112H37.8264V8.92162C37.9473 8.73595 38.1055 8.55955 38.3101 8.40173C38.4869 8.27176 38.7102 8.15107 38.9893 8.04894C39.259 7.93754 39.6126 7.89112 40.0312 7.89112C40.4312 7.89112 40.7661 7.95611 41.0452 8.0768C41.315 8.20677 41.5383 8.34603 41.7058 8.49457C41.8732 8.65239 41.9942 8.79165 42.0686 8.93091C42.143 9.07016 42.1802 9.15372 42.1802 9.18157C42.1802 9.20942 42.2174 9.163 42.2919 9.02375C42.3663 8.89377 42.4965 8.74523 42.6826 8.57812C42.8686 8.41101 43.1105 8.25319 43.4175 8.10465C43.7245 7.95611 44.1153 7.88184 44.5897 7.88184C45.0642 7.88184 45.4549 7.96539 45.7712 8.1325C46.0782 8.29961 46.3294 8.50385 46.5248 8.73595C46.7108 8.96804 46.8504 9.20014 46.9248 9.43223C47.0085 9.66433 47.0457 9.85001 47.0457 9.97998V15.3182H45.52V10.6763C45.52 10.5556 45.4828 10.4256 45.3991 10.2956C45.3154 10.1657 45.213 10.045 45.0921 9.94284C44.9618 9.84072 44.8223 9.75717 44.6548 9.6829C44.4874 9.60863 44.3199 9.58077 44.1432 9.58077C43.6315 9.58077 43.2036 9.72932 42.8779 10.0264C42.5523 10.3235 42.3849 10.7691 42.3849 11.3633V15.3182H40.8592V10.7041C40.8592 10.4163 40.7568 10.1564 40.5615 9.92428C40.3661 9.69218 40.0777 9.57149 39.7056 9.57149C39.4544 9.57149 39.2125 9.60863 38.9893 9.6829C38.7567 9.75717 38.5613 9.86857 38.3846 10.0171C38.2078 10.1657 38.0682 10.3513 37.9659 10.5741C37.8636 10.797 37.8078 11.0569 37.8078 11.354V15.3182H36.3007Z"
                            fill="white" />
                        <path
                            d="M51.9484 7.8916C52.4601 7.8916 52.9439 7.99372 53.3904 8.18868C53.8463 8.38364 54.237 8.65288 54.5719 8.98709C54.9068 9.32131 55.1766 9.72051 55.372 10.1754C55.5673 10.6303 55.6697 11.1131 55.6697 11.6237C55.6697 12.1343 55.5673 12.6171 55.372 13.0627C55.1766 13.5176 54.9068 13.9075 54.5719 14.2417C54.237 14.576 53.8463 14.8452 53.3904 15.0401C52.9346 15.2351 52.4601 15.3372 51.9484 15.3372C51.4368 15.3372 50.953 15.2351 50.5065 15.0401C50.0506 14.8452 49.6599 14.576 49.3157 14.2417C48.9714 13.9075 48.711 13.5176 48.5156 13.0627C48.3202 12.6078 48.2179 12.1343 48.2179 11.6237C48.2179 11.1131 48.3202 10.6303 48.5156 10.1754C48.711 9.72051 48.9807 9.32131 49.3157 8.98709C49.6599 8.65288 50.0506 8.38364 50.5065 8.18868C50.953 7.99372 51.4368 7.8916 51.9484 7.8916ZM51.9484 13.8704C52.2647 13.8704 52.5624 13.8147 52.8322 13.694C53.1113 13.5733 53.3439 13.4155 53.5486 13.2112C53.7532 13.007 53.9207 12.7656 54.0416 12.4964C54.1626 12.2271 54.2277 11.9301 54.2277 11.6144C54.2277 11.2988 54.1626 11.0017 54.0416 10.7325C53.9207 10.4632 53.7532 10.2218 53.5486 10.0176C53.3439 9.81335 53.102 9.64624 52.8322 9.52555C52.5531 9.40486 52.2647 9.34916 51.9484 9.34916C51.6321 9.34916 51.3344 9.41415 51.0646 9.52555C50.7856 9.64624 50.553 9.81335 50.3483 10.0176C50.1436 10.2218 49.9762 10.4632 49.8552 10.7325C49.7343 11.011 49.6785 11.2988 49.6785 11.6144C49.6785 11.9301 49.7343 12.2271 49.8552 12.4964C49.9762 12.7749 50.1343 13.007 50.3483 13.2112C50.553 13.4155 50.7949 13.5733 51.0646 13.694C51.3344 13.8147 51.6321 13.8704 51.9484 13.8704Z"
                            fill="white" />
                        <path
                            d="M56.9722 7.89112H58.4979V8.81022C58.6281 8.64311 58.8142 8.48528 59.0561 8.34603C59.2607 8.23462 59.5305 8.1325 59.8654 8.03038C60.2003 7.92825 60.619 7.88184 61.1213 7.88184V9.40438C60.8702 9.40438 60.5911 9.43223 60.2841 9.47865C59.9771 9.52507 59.6887 9.59934 59.4282 9.71075C59.1584 9.82215 58.9444 9.96141 58.7677 10.1378C58.5909 10.3142 58.4979 10.537 58.4979 10.797V15.3182H56.9722V7.89112Z"
                            fill="white" />
                        <path
                            d="M65.6332 7.8916C68.2009 7.8916 69.1126 10.0826 68.88 12.0693H63.568C63.8843 14.2417 66.8054 14.121 68.2009 13.1741V14.7152C61.3259 17.8253 59.4932 7.8916 65.6332 7.8916ZM67.3729 10.8439C67.2985 8.56932 63.7075 8.73643 63.568 10.8439H67.3729Z"
                            fill="white" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1298_2296">
                            <rect width="104.613" height="35" fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </Link>
        </>
    );
}