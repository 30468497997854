import { useRef, useState } from "react";
// @ts-ignore
import emailjs from "@emailjs/browser";

const inputConfig = {
    button: 'Send',
    buttonOnLoad: 'Sending...',
    placeholder: 'E-mail',
};

export const InputWithActionButton = () => {
    const [loading, setLoading] = useState(false)
    const form = useRef<HTMLFormElement>(null);

    const sendEmail = (e: any) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID!, process.env.REACT_APP_NEWSLETTER_TEMPLATE_ID!, form.current!, process.env.REACT_APP_EMAILJS_PUBLIC_KEY!)
            .then((_result: any) => {
                alert('Thank you for becoming a part of our newsletter community. Let’s stay in touch!');
                e.target.reset()
                setLoading(false)
            }, (_error: any) => {
                alert(`We couldn't add your email to our newsletter. Please try again later.`)
            });
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="flex rounded-md shadow-sm border-2 border-whitespace send-email-background">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                </div>
                <label htmlFor="from_email" className="sr-only">{inputConfig.placeholder}</label>
                <input
                    type="email"
                    name="from_email"
                    id="from_email"
                    autoComplete={"off"}
                    className="block w-full rounded-none appearance-none focus:outline-none rounded-l-md border-0 py-[9px] pl-5 bg-text font-normal text-base font-sans placeholder:font-sans placeholder:font-normal placeholder:text-base placeholder:bg-text placeholder:text-whitespace placeholder:leading-leading272 md:leading-leading272 placeholder:tracking-small"
                    placeholder={inputConfig.placeholder}
                    required={true}
                />
            </div>
            <button
                type="submit"
                className="relative inline-flex items-center gap-x-1.5 rounded px-5 text-base leading-leading208 font-semibold text-text bg-whitespace duration-500 hover:bg-active"
            >
                {loading === true ?
                    (
                        <p className="cursor-not-allowed">{inputConfig.buttonOnLoad}</p>
                    ) : (
                        <p>{inputConfig.button}</p>
                    )}
            </button>
        </form>
    );
};