export const Header = ({ title, text }: {title: string, text: string}) => {
    return (
        <div
            className="grid md:grid-cols-2 grid-cols-1 border-b border-b-text md:py-10 pt-pcustom30 px-5 xl:px-0 border-t border-t-text">
            <span
                className="pb-7 md:py-0 font-serif font-semibold text-5xl md:text-6xl md:text-header leading-leading624 md:leading-leading957">
                {title}
            </span>
            <p
                className="mb-10 md:mb-0 md:mt-2 md:ml-2.5 font-sans text-[16px] leading-leading272 font-[400] tracking-small">
                {text}
            </p>
        </div>
    );
};