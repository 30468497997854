import { Link } from "react-router-dom";

export const Button = ({ content }: { content: string }) => {
    return (
        <Link
            to={"/contact"}
            className="bg-text leading-leading286 rounded-xl w-full md:w-[146px] h-[57px] text-center text-white pt-[14px] px-6 md:px-2 md:px-[25px] text-font22 font-medium duration-500 hover:bg-active cursor-pointer"
        >
            {content}
        </Link>
    );
}