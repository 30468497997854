import { useTitle } from 'react-use';
// import vision1 from '../assets/images/vision/Vision_1.jpg';
// import vision2 from '../assets/images/vision/Vision_2.jpg';
// import vision3 from '../assets/images/vision/Vision_3.jpg';
import vision1 from '../assets/newImages/vision/Vision1x1200.jpg';
import vision2 from '../assets/newImages/vision/Vision2x1200.jpg';
import vision3 from '../assets/newImages/vision/Vision3x1200.jpg';
import { Contact } from "../shared/Contact";
import { Header } from "../shared/Header";

const ourVision = {
    header: {
        title: 'Our Vision',
        content: 'To facilitate the widespread adoption of the Metaverse, we need to build accessible technologies that will demystify Web3. Our platform is future forward, green and allows buyers and sellers to get involved now, not tomorrow.',
    },
    contentSections: [
        {
            header: 'Effortless entry into the Metaverse',
            content: 'We allow for absolutely effortless adoption of sophisticated blockchain solutions. Enterprises of all sizes and individuals using our solutions can rest easy and proudly identify as metaverse frontiers. That is the only way forward.',
            listElements: [
                {
                    paragraph: 'Take a stance. Be a metaverse frontier. Harness the power of blockchain. All on us.'
                },
                {
                    paragraph: 'Enter the new era of engagement with your audience. Don’t miss the dawn of digital ownership.'
                },
                {
                    paragraph: 'Leave all the challenges of adopting cutting edge technology with our dedicated team.'
                },
            ],
            image: (
                <img
                    src={vision1}
                    alt="section photo"
                    className="aspect-[4/3] sm:aspect-[1200/457] md:max-h-[457px] rounded-2xl lg:rounded-3xl bg-gray-100 object-cover "
                />
            ),
        },
        {
            header: 'Winning with speed',
            content: 'In order to succeed in the blockchain space you need to move fast. Our solution only requires you to have a bank account to receive payouts. Our partners leverage the multitude of advantages that come with embracing the metaverse.',
            listElements: [
                {
                    paragraph: 'Our solution is instantly deployable and does not require our partners to have expertise in blockchain.'
                },
                {
                    paragraph: 'We swiftly consider our partners individual needs and perspectives at every step of decision making.'
                },
                {
                    paragraph: 'Our ability to adapt to our partners expectations and excel comes from our dedication to excellence and deep understanding of technologies involved.'
                },
            ],
            image: <img src={vision2} alt="section photo"
                className="aspect-[4/3] w-full rounded-2xl md:rounded-3xl bg-gray-100 object-cover md:aspect-[1200/457] md:max-h-[224px] md:max-h-[457px]" />,
        },
        {
            header: 'Only the highest quality partnerships',
            content: 'Our dedication to excellence permeates every aspect of our work, including the careful selection of our partners. We envision the Digital Art on Blockchain space as a realm for genuine, verified, and instantly valuable collectibles. This extends to our commitment to being carbon neutral.',
            listElements: [
                {
                    paragraph: 'Every Digital Art Piece sold using our solution is guaranteed to be of the highest quality and value.'
                },
                {
                    paragraph: 'We are committed to being carbon neutral and plant one tree for each Digital Art Piece sold. The future is green.'
                },
                {
                    paragraph: 'Everyone can start collecting Digital Art Pieces right away. Only email required. Build a thriving community based on real value.'
                },
            ],
            image: <img src={vision3} alt="section photo"
                className="aspect-[4/3] w-full rounded-2xl md:rounded-3xl bg-gray-100 object-cover aspect-[1200/457] max-h-[457px]" />,
        },
    ]
};

export const OurVision = () => {
    useTitle('More Metaverse | Our vision');

    return (
        <div className="max-w-off mx-auto">
            <Header
                title={ourVision.header.title}
                text={ourVision.header.content}
            />
            <main className="px-5 xl:px-0">
                {ourVision.contentSections.map((contentSection, index) => {
                    return (
                        <div key={index} className="w-full border-b border-b-text pt-pcustom30 md:pt-pcustom60">
                            {contentSection.image}
                            <div className="pt-pcustom30 pb-10 md:py-pcustom60 grid md:grid-cols-2 md:gap-4">
                                <h2
                                    className="font-semibold text-font32 md:text-5xl font-serif leading-leading416 md:leading-leading624"
                                >
                                    {contentSection?.header}
                                </h2>
                                <div className="pt-6 md:pt-0">
                                    <p
                                        className="text-font17 md:text-lg leading-leading272 md:leading-leading306 md:tracking-small2 font-normal">{contentSection?.content}</p>
                                    {contentSection?.listElements?.length > 0 ? (<ul className="pt-3.5 mb-1.5 md:pt-pcustom18">
                                        {contentSection?.listElements?.map((element, i) => (
                                            <li key={i} className="pb-3.5 last:pb-0 md:pb-2 flex">
                                                <div>
                                                    <svg id="magicoon-Bold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                        className="h-[18px] w-5 flex-none mr-m16 mt-1">
                                                        <title>arrow-right</title>
                                                        <g id="arrow-right-Bold">
                                                            <path id="arrow-right-Bold-2" data-name="arrow-right-Bold" className="cls-1"
                                                                d="M21.707,12.707l-7,7a1,1,0,0,1-1.414-1.414L18.586,13H3a1,1,0,0,1,0-2H18.586L13.293,5.707a1,1,0,0,1,1.414-1.414l7,7A1,1,0,0,1,21.707,12.707Z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <p className="text-font17 leading-leading272 tracking-small">{element?.paragraph}</p>
                                            </li>
                                        )
                                        )}
                                    </ul>) : null}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </main>
            <Contact />
        </div>
    );
};
