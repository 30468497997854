import { Link } from "react-router-dom";
import { Header } from "../shared/Header";
import { Accordion } from "./Accordion";
import { useTitle } from "react-use";

const faqContent = {
    header: {
        title: 'FAQ',
        content: 'Your Questions Answered. Quickly find answers to common queries and get the information you need hassle-free.'
    },
    faqList: [
        {
            title: 'What is a Digital Art Piece on Blockchain?',
            content: 'Simply put, a Digital Art Piece on Blockchain is a digital certificate of authenticity and of ownership over a digital asset. It is the only way to truly own a digital asset. It is like an author’s signature on a piece of art, however it is not possible to forge it and once created it lives forever on a blockchain. Any Digital Art Piece is truly irreplaceable and anyone can verify its authenticity. This is a massive improvement over traditional pieces of art and collectibles that can be forged, lost, stolen or destroyed. Those Digital Art Pieces can be representations of existing art pieces or be completely new.'
        },
        {
            title: 'Do I need cryptocurrency to buy and sell Digital Art Pieces on Blockchain?',
            content: 'No, at no point you will need to use cryptocurrency nor cryptocurrency exchanges. All payments are done with credit and debit cards or with Apple Pay and Google Pay.'
        },
        {
            title: 'What is the Digital Art Wallet?',
            content: 'In short, a Digital Art Wallet is your secure place to store and manage your Digital Art Pieces directly via connecting to Blockchain. You need one to start collecting. You only need your email address.'
        },
        {
            title: 'Do I need cryptocurrency to manage my Digital Art Pieces?',
            content: 'The More Metaverse Digital Art Wallet allows you to freely transfer Digital Art Pieces without paying gas fees. This means at no point you will need cryptocurrency. This is made possible by the cutting edge technology our teams diligently designed and implemented.'
        },
        {
            title: 'How come there are no fees on Blockchain?',
            content: 'Even though our solution is based on Ethereum, we eliminated gas fees for sellers and buyers. This is possible due to the cutting edge technology we implemented. Our team of dedicated software developers is constantly working on making Blockchain and Metaverse accessible for all.'
        },
        {
            title: 'Do I need to have a website to offer Digital Art Pieces for sale?',
            content: 'We provide our partners with a beautiful customized website with an integrated Digital Art Wallet for buyers. We also have the capability to fully integrate into our partner’s website or create a new section, a custom built store ready to be deployed.'
        },
        {
            title: 'Do I need to verify my identity in order to buy a Digital Art Piece?',
            content: 'No, at no point you will need to provide your ID or passport. We care about your privacy.'
        },
        {
            title: 'Can anyone create and sell using your solution?',
            content: <>We strive to work only with the best and offer exclusively the highest quality Digital Art Pieces. If you would like
                to work with us click <Link to="/contact" className="font-semibold underline cursor-pointer">here</Link> and one of
                the More Metaverse experts will discuss the details of our partnership.</>
        },
    ],
};

export const FAQ = () => {
    useTitle('More Metaverse | FAQ');

    return (
        <div className="max-w-off mx-auto">
            <Header title={faqContent.header.title}
                text={faqContent.header.content}
            />
            <Accordion faqList={faqContent.faqList} />
        </div>
    );
};
