import { Button } from "../shared/Button";
// import websitePhoto from '../assets/images/main/Banner_main.jpg';
import websitePhoto from '../assets/newImages/main/MainPage.jpg';

const buyAndSell = {
    // header: 'Buy and Sell NFTs, Better.',
    content: 'Blockchain verified ownership. Forever. Only email and credit card required for you and collectors.',
    button: 'Let\'s talk',
};

export const BuyAndSell = () => {
    return (
        <div
            className="border-t border-t-text max-w-off md:h-fit px-5 pb-pcustom89 pt-pcustom38 md:pt-pcustom30 md:pt-pcustom60 mx-auto xl:px-0">
            <div className="grid grid-cols-1 md:grid-rows-1 md:grid-cols-[1fr_2fr] h-full">
                <div className="self-center md:text-left">
                    <h1
                        className="font-serif font-semibold text-5xl md:text-technologies text-text leading-leading624 md:leading-leading675"
                    >
                        Art.
                        <br />
                        On Blockchain.
                    </h1>
                    <p className="my-6 leading-leading272 tracking-small">
                        {buyAndSell.content}
                    </p>
                    <div className="flex items-center ">
                        <Button content={buyAndSell.button} />
                    </div>
                </div>
                <div
                    className="mb-5 py-8 md:py-14 mr-16 md:mr-14 bg-whitespace bg-opacity-60 rounded-3xl order-first md:order-last md:ml-auto h-fit w-fit">
                    <div className="max-w md:w-full w-[calc(100%+3rem)] relative left-6 md:left-14 md:max-w-2xl">
                        <img
                            src={websitePhoto}
                            alt="test photo"
                            className="rounded-2xl drop-shadow-boxshadow"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
