import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { FAQ } from './FAQ/Faq';
import { PageNotFound } from './PageNotFound';
import { Home } from './Home/Home';
import { OurVision } from './OurVision/OurVision';
import { Careers } from './Careers/Careers';
import { ContactWrapper } from './Contact/ContactWrapper';
import { ApolloProvider } from '@apollo/client';
import { client } from './services/apollo-client';
import { Blog } from './Blog/Blog';
import { Post } from './Blog/Post';
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <PageNotFound />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 'faq',
                element: <FAQ />
            },
            {
                path: 'our-vision',
                element: <OurVision />
            },
            {
                path: 'careers',
                element: <Careers />
            },
            {
                path: 'contact',
                element: <ContactWrapper />
            },
            {
                path: 'blog',
                element: <Blog />
            },
            {
                path: 'blog/:slug',
                element: <Post />
            },
            {
                path: 'privacy-policy',
                element: <PrivacyPolicy />
            },
            {
                path: '*',
                element: <Navigate to='' replace />
            },
        ],
    },
]);

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <RouterProvider router={router} />
        </ApolloProvider>
    </React.StrictMode>
);
