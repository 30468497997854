import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import cn from "classnames";

type Props = {
    initialPage?: number;
    pageSize: number;
    itemsLength: number;
    onPageChange: (page: number) => void;
};

export const Pagination = ({initialPage = 1, pageSize, itemsLength, onPageChange}: Props) => {
    const [page, setPage] = useState(initialPage);

    useEffect(() => {
        onPageChange(page);
    }, [page]);

    const pagesAmount = Math.ceil(itemsLength / pageSize);
    let pagesToDisplay: Array<number | '...'> = [];
    if (pagesAmount > 7) {
        if (page === 1 || page === 2 || page === 3) {
            pagesToDisplay = [1, 2, 3, 4, 5, '...', pagesAmount];
        } else if (page === pagesAmount || page === (pagesAmount - 1) || page === (pagesAmount - 2) || page === (pagesAmount - 3)) {
            pagesToDisplay = [1, '...', pagesAmount - 4, pagesAmount - 3, pagesAmount - 2, pagesAmount - 1, pagesAmount];
        } else {
            pagesToDisplay = [1, '...', page - 1, page, page + 1, '...', pagesAmount];
        }
    } else {
        pagesToDisplay = Array.from({ length: pagesAmount }, (_, i) => i + 1);
    }

    return (
        <ul className="list-style-none flex justify-center">
            <li>
                <button
                    disabled={page === 1}
                    className="flex justify-center bg-transparent w-9 h-9 text-sm hover:text-active disabled:pointer-events-none"
                    onClick={() => setPage((prev) => prev - 1)}
                >
                    <ChevronLeftIcon className="w-8 h-8 " />
                </button>
            </li>
            {pagesToDisplay.map(pageNumber => {
                if (pageNumber === '...') {
                    return (
                        <li>
                            <span
                                className="justify-center block bg-transparent w-9 h-9 text-xl flex items-center cursor-default"
                            >{page}</span>
                        </li>
                    );
                }
                return (
                    <li>
                        <span
                            className={cn({ 'text-active': page === pageNumber }, "justify-center block bg-transparent w-9 h-9 text-xl hover:text-active flex items-center cursor-pointer")}
                            onClick={() => setPage(pageNumber)}
                        >{pageNumber}</span>
                    </li>
                )
            })}
            <li>
                <button
                    disabled={page === pagesAmount}
                    className="flex justify-center bg-transparent w-9 h-9 text-sm hover:text-active disabled:pointer-events-none"
                    onClick={() => setPage((prev) => prev + 1)}
                >
                    <ChevronRightIcon className="w-8 h-8" />
                </button>
            </li>
        </ul>
    )
}