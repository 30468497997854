import useEmblaCarousel from "embla-carousel-react";

export const Carousel = ({ children }: {children: JSX.Element[]}) => {
    const [emblaRef] = useEmblaCarousel({slidesToScroll: 'auto', containScroll: 'trimSnaps'});

    return (
        <div className="embla px-4 overflow-hidden">
            <div className="embla_vieport overflow-hidden" ref={emblaRef}>
                <div className="embla_container flex -ml-4">
                    {children.map((slide, index) => (
                        <div
                            key={index}
                            className="embla_slide flex-carousel pl-4"
                        >
                            {slide}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
