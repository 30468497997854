import { useTitle } from "react-use";
import { Header } from "../shared/Header";
import { BlogImportantPosts } from "./BlogImportantPosts";
import { BlogPosts } from "./BlogPosts";

const blogContent = {
    header: {
        title: 'Blog',
        content: 'Our Blog is a space to learn more about Blockchain, Web3 and the Metaverse. We also publish articles highlighting our opinions and achievements.'
    },
}

const blogPostsList = [
    {
        title: 'The extra bit of news',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1685318400&Signature=UBybGr14Q7f3wgmV~-e9Pk7CJ4s9paDJL6wjkGyW79fn1OZwJucDhRQ3cclPb9wXtDjSzRwzGQrryiBszTq3wDtfVeGVtoeufkumobX8Wm3Q7oVkNQ~TTjhHwlsX1t7Ohac3XrZPc38heGqglgBBdbpDp2gK9u0uKP0BmK5xDCBx10JvMjbmnpgzbE-6CKUHgQ-QfCn1ngud7tU4FYmGxVc7g-77lcq1wxNc05lSMjxPtR6flXianl5i3~nQmuXaTNMRgh9Dzqe~ihS3In7tVWVeApSYmwWQqBlbwnaMsNY7YoKwQuNjSdwCkUdM5iQ6-~T-4wWhzS3bI1frbfK1KA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: true,
        featuredPost: true,

    }, {
        title: 'The future is here and you should be ready',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/6adb/e675/6782504d76e47292c777750120063650?Expires=1685318400&Signature=TewNLo8DHEH64vL-722Lc9t7szKZ1MnA0IpuVHxVsAYpzfpGwDAGAiRzm0el2x3iMHbn6YBLh1moGG8RghKeQMCngk6wr7ppOJ0iAyZ1A8B7NX8ECEStNRf0-uG-aYKtq2kaCGVKJtEKWGgWM7HAaInbwYfbmEEkfofwhmssytjtJGsxgGeSOREqwfB3l2qy5eIr6XwuNvr7Vcvo6wZHOcOMvZs4xtRuSqJstuZqgyugv4q7NYwasag14-w3p8JKuyA41z1C~l477RXGWkDu2oyA0C2-QLDZGnEIXk-0YCz9Cl75AyXR~4ovN2pqaHbBOxeXGFJoysDMZCNv2CrQ-g__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: true,
        featuredPost: false,
    }, {
        title: 'What is Metaverse?',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/58fd/cad4/9b2c89624987379ef242a5d809d39ff3?Expires=1685318400&Signature=pYhq6zPwkt-nBMWggnQK7y6TxeEdH-qcpSsYNO47aB1rv~bZhnQS8ouBrHGP8Q1TG~wBYb7soy4yBRcZzyPuTD6VmrwoCDU~GLa0g7177by1odQ5hV169yYysRm2aRzB86A7AxcoQ8L7l70pIYpPYn7SWDAHsOpc92C6f6E~bJyt-yc6EqMiFWvrhvKkwZEm5ITOFXKO3RHw1KAdytCmmuuKop9kapuCq4CjiCyGfxVU7iWyFM2dAw6XzY~m~QLsn4p2uGoxcWEifEKtklXLki-UE43Wb~4SRfebCEIth1uOCBajoJicJBxplfe0x8iY7zsdNCRDOagAS5VNBgIr9Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: false,
        featuredPost: false,
    }, {
        title: 'Faster, harder, NFT',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/9d6c/befe/8e26d4a560a576519d4544ed9b830645?Expires=1685318400&Signature=XsG~ZTDlojn479TxJdK8igA1A~o9biRvfEswfmcQMHXRUFQbm-Hd0SWOo9ks9XlJscqwLiqVUCCn6Zcy7m4ZcacE7ZYvz~bGU71cHRrXLbVjNgyOzShvMreq7lekXh0ah-B-PFwdXq7MgkJXSlCnSiRALyqCLZw5w8Bc8VDlMvmR7bMLwLe~eaK-Ngo2vKZYXhalf4-Q7u-DhBLK3c1KCLJKbi12fW0pvWewaIrnRvOoIFY5bbZrz2~1bumJgZOuulNwU7sSzxpLxKdDvSjX3mfb3mJjbgfspaz5-pe-jbMCXgoyUSZCXs1FLa1Y81dYIz7AU-A4GlwXj4En69eLbw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: true,
        featuredPost: true,
    }, {
        title: 'AI want to be your friend',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/1dcc/ca55/9eaf7061c8736d5cd9035763ad588dbf?Expires=1685318400&Signature=MxB1PmpJYYS77qnnfKRqko7K6frDYUih6KXp5EDNJmOyhkh~9j0wWVXiubinRM7PMQU2AV0IDFtaPEKAd7UDmHrDD853DeX45gmaaZvG9jhKXduIPnw3Ql-Q8ftosb55Zsd4NT--KIaxMlBZYoJe7N2FmWewIoRRDSFix78LRnskUwoTYz4vyYbB9xzW4oA98FS3cIZQQk0YiHmnoE94O4P401HHyHyw8hY1IfQZTTbhASPS5gbcIiHyaYOBcCueuUoovBnTuC3Lb0s53pW1lMMV226Kdba~hHlAJeBsUR64WHvdJM5ek~pEI2NyqpvjW76X9kae7wxp7KkdPxCNoQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: false,
        featuredPost: false,
    }, {
        title: 'Metaverse universe',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/481c/3a42/3606cd15fea4e54cc1c489650b97d4d5?Expires=1685318400&Signature=OIK3IspG5357fXrmeqFltqRjq6x01~MS2nBBdnfLG7yT3UH0SB8s9u7X-qKlEN-wEdWsh1ycw4kt9Ib~aT6sQgR2DmatsTSZMdxW2YLhaT8KbGpbV5UswrWNpfARWTNnfp5VsN7JdFxJiCFczpKD8kLHGpT9rty0Rp9VlLPZwxC2RkOtPnCoIGgWUg9Z2uNBEY30B26wQj-u62TioQxmT1XfhH5r0hFKuznFw2pojZZUrevdxQCIbCXebvuwE978JNim3tYphd9SLfGbq-DvIygYdRQNRleOXGPCxL73vYxVVubq7BbccP1TLJoZPa07ZN~KWZoWwJnT~x6JMUdzEg__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: false,
        featuredPost: false,
    }, {
        title: 'The extra bit of news',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1685318400&Signature=UBybGr14Q7f3wgmV~-e9Pk7CJ4s9paDJL6wjkGyW79fn1OZwJucDhRQ3cclPb9wXtDjSzRwzGQrryiBszTq3wDtfVeGVtoeufkumobX8Wm3Q7oVkNQ~TTjhHwlsX1t7Ohac3XrZPc38heGqglgBBdbpDp2gK9u0uKP0BmK5xDCBx10JvMjbmnpgzbE-6CKUHgQ-QfCn1ngud7tU4FYmGxVc7g-77lcq1wxNc05lSMjxPtR6flXianl5i3~nQmuXaTNMRgh9Dzqe~ihS3In7tVWVeApSYmwWQqBlbwnaMsNY7YoKwQuNjSdwCkUdM5iQ6-~T-4wWhzS3bI1frbfK1KA__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: false,
        featuredPost: false,
    }, {
        title: 'Blockchain explained',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/58b3/9278/559f03862bd93f097c8721132c32579e?Expires=1685318400&Signature=OQL3ng7nBvrZsbc5wivNKT3wJ5fqg3Ez8xtn3GmCsv1N-tR9HLTTgFecp4tIIwbKdVpGwPsWXlcReZVEim~BR4J8Awvht78V7zJEGnwsdfJEp0H8TXKdUOTipzXU2gnZMCez~ROsThWvyYzzhmhAEXdgphJrmIsEJ42jcRDE8ZitVFL3V29sPu6qamHlLTsqMVGF~F-TbWilx4D0n8VW08iNM4lY7FIsb9lbbCh57BCwaBhAvXwewYlsmQDGnHd3VxD9alJ4NEWfc04Kqj5X5XKIiOLFDkye7gEvXc5GUZro1N1j97QlVzPy1AMXRkNK~~ugnTBWdO5zAAJFC5b0xw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: false,
        featuredPost: false,
    }, {
        title: 'Metaverse universe',
        author: 'Jan Nowak',
        createdAt: 'January 6th, 2023',
        tags: ['Tech'],
        image: 'https://s3-alpha-sig.figma.com/img/cb5e/e070/4f12baf50c17ae059e5e90fec881af87?Expires=1685318400&Signature=kSEYgtKnIcv72HqQampbGWPUHPxLkz5Pj8J0ALm4NWZxzAGpz-rRJMkaug4jUop35XEcptbYbGcv5G1oXZDQ~weve8~HloGyZMRwKdiyOGvWYRaLff9yV-Ypj3HJwwypTCK5GUCn0~ByAiIfc4qlV7IKQ0wEMTCa0GblAjdWvZB7H-~BjznHERoBJ8Wu3QdYC4MtFl~ZsT4W~NZOAWQW9lhAn~Ob3x0~aIKFzRkOb6fjDw9yK-qRVX540GZsyTjOlmnPax0jBP0lII1UBiu9sc~OiEr-0VHLvNCCyQwdXLEa1k0zMn7IoMMB~3vyYFiFp7QVOxLONpe8t34kjzTAjQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4',
        content: '',
        slug: '/',
        mainRecommendedPost: false,
        featuredPost: false,
    },
];

export const Blog = () => {
    useTitle('More Metaverse | Blog');

    return (
        <div className="max-w-off mx-auto">
            <Header title={blogContent.header.title}
                text={blogContent.header.content}
            />
            <div>
                <BlogImportantPosts />
                <BlogPosts />
            </div>
        </div>
    );
};
