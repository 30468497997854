import { Cog8ToothIcon } from "@heroicons/react/20/solid";
import { Carousel } from "../shared/Carousel";
import { Link } from "react-router-dom";

const tables = [
    {
        header: 'Hands off solution for sellers',
        icon: <div>
            <svg id="magicoon-Filled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                className="w-7 h-7 mr-2 md:mr-3">
                <title>users-three</title>
                <g id="users-three-Filled">
                    <path id="users-three-Filled-2" data-name="users-three-Filled" className="cls-1"
                        d="M20.35,6.71a3.694,3.694,0,0,1-2.61,3.53A5.91,5.91,0,0,0,18,8.5a5.99,5.99,0,0,0-2.93-5.15A3.769,3.769,0,0,1,16.65,3,3.707,3.707,0,0,1,20.35,6.71ZM19.6,11.3a1.646,1.646,0,0,0-1.25.15,3.33,3.33,0,0,1-1.39.41,5.1,5.1,0,0,1-.7.85A5.425,5.425,0,0,1,19.92,17h.69a1.228,1.228,0,0,0,1.09-.63,2.551,2.551,0,0,0,.3-1.21v-.71A3.241,3.241,0,0,0,19.6,11.3ZM8.93,3.35A3.769,3.769,0,0,0,7.35,3a3.7,3.7,0,0,0-1.09,7.24A5.91,5.91,0,0,1,6,8.5,5.99,5.99,0,0,1,8.93,3.35ZM7.74,12.71a5.1,5.1,0,0,1-.7-.85,3.33,3.33,0,0,1-1.39-.41A1.646,1.646,0,0,0,4.4,11.3,3.241,3.241,0,0,0,2,14.45v.71a2.551,2.551,0,0,0,.3,1.21A1.224,1.224,0,0,0,3.38,17h.7A5.425,5.425,0,0,1,7.74,12.71Zm7.84,1.36a2.333,2.333,0,0,0-.53-.07,2.033,2.033,0,0,0-.99.26,4.268,4.268,0,0,1-4.12,0A2.033,2.033,0,0,0,8.95,14a2.333,2.333,0,0,0-.53.07A3.955,3.955,0,0,0,5.5,17.9v.87a3.073,3.073,0,0,0,.37,1.46A1.455,1.455,0,0,0,7.18,21h9.64a1.455,1.455,0,0,0,1.31-.77,3.073,3.073,0,0,0,.37-1.46V17.9A3.955,3.955,0,0,0,15.58,14.07ZM16.5,8.5A4.5,4.5,0,1,0,12,13,4.5,4.5,0,0,0,16.5,8.5Z" />
                </g>
            </svg>
        </div>,
        list: [
            { content: 'Set the price, choose the piece you want to put on blockchain and we take care of the rest.' },
            { content: 'You will receive payouts directly to your bank account.' },
            { content: 'We create a custom website where buyers will be able to purchase and collect digital art you offer. Alternatively, we can completely integrate and allow collecting on your website.' },
        ]
    },
    {
        header: 'Effortless solution for buyers',
        icon: <Cog8ToothIcon className="w-7 h-7 mr-2 md:mr-3" />,
        list: [
            { content: 'Only email and credit card necessary to buy and manage art on blockchain. 2FA enabled.' },
            { content: 'No fees to manage digital art on blockchain.' },
            { content: 'No entry barrier to start collecting digital art on blockchain and participating in the new digital economy.' },
        ]
    },
];

const mainInfo = {
    header: 'See why top companies choose More Metaverse',
    tables: [
        {
            header: 'Hands off solution for sellers',
            icon: <div>
                <svg id="magicoon-Filled" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                    className="w-7 h-7 mr-2 md:mr-3">
                    <title>users-three</title>
                    <g id="users-three-Filled">
                        <path id="users-three-Filled-2" data-name="users-three-Filled" className="cls-1"
                            d="M20.35,6.71a3.694,3.694,0,0,1-2.61,3.53A5.91,5.91,0,0,0,18,8.5a5.99,5.99,0,0,0-2.93-5.15A3.769,3.769,0,0,1,16.65,3,3.707,3.707,0,0,1,20.35,6.71ZM19.6,11.3a1.646,1.646,0,0,0-1.25.15,3.33,3.33,0,0,1-1.39.41,5.1,5.1,0,0,1-.7.85A5.425,5.425,0,0,1,19.92,17h.69a1.228,1.228,0,0,0,1.09-.63,2.551,2.551,0,0,0,.3-1.21v-.71A3.241,3.241,0,0,0,19.6,11.3ZM8.93,3.35A3.769,3.769,0,0,0,7.35,3a3.7,3.7,0,0,0-1.09,7.24A5.91,5.91,0,0,1,6,8.5,5.99,5.99,0,0,1,8.93,3.35ZM7.74,12.71a5.1,5.1,0,0,1-.7-.85,3.33,3.33,0,0,1-1.39-.41A1.646,1.646,0,0,0,4.4,11.3,3.241,3.241,0,0,0,2,14.45v.71a2.551,2.551,0,0,0,.3,1.21A1.224,1.224,0,0,0,3.38,17h.7A5.425,5.425,0,0,1,7.74,12.71Zm7.84,1.36a2.333,2.333,0,0,0-.53-.07,2.033,2.033,0,0,0-.99.26,4.268,4.268,0,0,1-4.12,0A2.033,2.033,0,0,0,8.95,14a2.333,2.333,0,0,0-.53.07A3.955,3.955,0,0,0,5.5,17.9v.87a3.073,3.073,0,0,0,.37,1.46A1.455,1.455,0,0,0,7.18,21h9.64a1.455,1.455,0,0,0,1.31-.77,3.073,3.073,0,0,0,.37-1.46V17.9A3.955,3.955,0,0,0,15.58,14.07ZM16.5,8.5A4.5,4.5,0,1,0,12,13,4.5,4.5,0,0,0,16.5,8.5Z" />
                    </g>
                </svg>
            </div>,
            list: [
                { content: 'Upload the content you want to sell as NFTs, set the price and we take care of the rest.' },
                { content: 'You will not pay gas fees to create NFTs. You have direct control over your ETH address. 2FA Enabled.' },
                { content: 'You will receive payouts directly to your bank account.' },
            ]
        },
        {
            header: 'Effortless solution for buyers',
            icon: <Cog8ToothIcon className="w-7 h-7 mr-2 md:mr-3" />,
            list: [
                { content: 'Only an email address and a credit card is needed to buy and manage NFTs. 2FA enabled.' },
                { content: 'No need to pay for gas fees to transfer collected NFTs. ' },
                { content: 'No entry barrier to start collecting NFTs' },
            ]
        },
    ],
    button: 'Learn more',
};

export const SummaryInfo = () => {
    return (
        <div
            className="max-w-off mx-auto bg-background md:rounded-3xl py-pcustom30 md:pb-20 md:pt-[88px] md:mt-10 md:px-10 md:px-pcustom82">
            <div
                className="grid grid-cols-1 md:grid-cols-3 md:grid-cols-7 items-center justify-between pb-10 md:pb-0 px-5 md:px-0 w-4/5 md:w-full">
                <h3
                    className="md:col-span-2 md:col-span-4 md:col-span-5 font-serif font-semibold text-technologiesMobile md:text-font32 leading-leading364 md:leading-leading416 pb-7 md:pb-0 ">
                    {mainInfo.header}
                </h3>
                <div className="md:col-span-2 pb-5 md:pb-0 md:justify-self-end">
                    <Link
                        to={"/contact"}
                        className="bg-text leading-leading286 rounded-xl w-full md:w-[146px] h-[57px] text-center text-white py-[14px] px-6 md:px-2 md:px-[25px] text-font22 font-medium duration-500 hover:bg-active cursor-pointer"
                    >
                        {mainInfo.button}
                    </Link>

                </div>
            </div>
            <section className="hidden md:inline-grid md:grid-cols-2 md:grid-rows-1 md:pt-[67px] md:gap-5">
                {tables.map((info, index) => {
                    return (
                        <div key={index}
                            className="bg-frameColor row-span-1 col-span-1 rounded-2xl px-5 pt-6 pb-2.5 md:p-10">
                            <h5
                                className="text-font22 pb-6 md:pb-[18px] font-semibold leading-leading286 flex">
                                <span>{info?.icon}</span>{info?.header}</h5>
                            {info?.list?.length > 0 ? (
                                <ul>
                                    {info.list.map((element, index) => {
                                        return (
                                            <li key={index}
                                                className="text-sm md:text-font17 font-normal leading-leading224 md:leading-leading272 tracking-small2 md:tracking-small1 py-3.5 border-b border-b-text border-opacity-20 last:border-0">
                                                {element.content}
                                            </li>
                                        )
                                    })}
                                </ul>
                            ) : null}
                        </div>
                    )
                })}
            </section>
            <section className="md:hidden md:pt-pcustom60 px-1">
                <Carousel>
                    {tables.map((info, index) => {
                        return (
                            <div key={index}
                                className="md:mr-5 row-span-1 bg-frameColor rounded-2xl px-5 pt-6 pb-2.5 md:p-10">
                                <h5
                                    className="text-font22 pb-6 md:pb-8 font-semibold leading-leading286 flex">
                                    <span>{info.icon}</span>{info.header}
                                </h5>
                                <ul>
                                    {info.list.map((element, index) => {
                                        return (
                                            <li key={index}
                                                className="text-sm md:text-base leading-leading224 md:leading-leading272 tracking-small2 md:tracking-small1 py-3.5 border-b border-b-text border-opacity-20 last:border-0">
                                                {element.content}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </Carousel>
            </section>
        </div>
    );
};