import { useTitle } from "react-use";
import { Contact } from "../shared/Contact";

export const ContactWrapper = () => {
    useTitle('More Metaverse | Contact');

    return (
        <div className="max-w-off mx-auto md:mb-m54 border-t border-t-text">
            <Contact />
        </div>
    );
};