import { Link } from "react-router-dom";

export const BrandLogo = () => {
    return (
        <div className="mt-1 md:mt-0">
            <Link to="/" className="hidden md:block">
                <svg width="156" height="52" viewBox="0 0 156 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.00758 41.5587C10.2976 41.5587 12.9648 38.8847 12.9648 35.5862C12.9648 32.2878 10.2976 29.6138 7.00758 29.6138C3.71753 29.6138 1.05042 32.2878 1.05042 35.5862C1.05042 38.8847 3.71753 41.5587 7.00758 41.5587Z"
                        fill="#3772FF" />
                    <path
                        d="M36.8211 29.5449C35.3421 29.5449 33.9738 30.0828 32.9372 30.9931C29.9517 33.5035 30.4354 37.7931 28.4589 39.0345C27.1044 39.8897 24.9482 39.0759 22.5847 39.0069C22.3497 38.9793 22.1286 38.9656 21.8798 38.9656C18.5902 38.9656 15.9226 41.6414 15.9226 44.938C15.9226 48.2345 18.5902 50.9104 21.8798 50.9104C22.8473 50.9104 23.7734 50.6759 24.575 50.2621C24.6718 50.2207 24.7685 50.1793 24.8514 50.1242C24.9758 50.0414 25.0864 49.9724 25.197 49.8897C26.0816 49.2966 26.7865 48.4828 27.2426 47.5173C28.528 45.1724 28.6662 42.4966 30.0208 41.5724C32.2323 40.069 36.1853 42.9793 39.9724 40.5931C40.0139 40.5655 40.0415 40.538 40.083 40.5104C41.7001 39.4483 42.7644 37.6138 42.7644 35.5311C42.7782 32.2207 40.1106 29.5449 36.8211 29.5449Z"
                        fill="#080108" />
                    <path
                        d="M25.1003 12.2621C25.1417 12.2345 25.1694 12.2069 25.2108 12.1793C26.828 11.1173 27.8923 9.28277 27.8923 7.20001C27.8923 3.90346 25.2247 1.2276 21.9351 1.2276H21.9213H21.9075C20.0001 1.2276 18.3138 2.12415 17.2219 3.51726C15.1486 6.05519 15.3421 9.60001 13.5868 10.7173C12.2322 11.5724 10.1037 10.7586 7.754 10.6897C7.51903 10.6621 7.28406 10.6483 7.04909 10.6483C5.87424 10.6483 4.7685 10.9931 3.85627 11.5862C3.78716 11.6276 3.71805 11.669 3.64894 11.7104C3.57983 11.7517 3.52454 11.7931 3.46926 11.8483C2.01798 12.9379 1.09192 14.6621 1.09192 16.6207C1.09192 19.9173 3.75951 22.5931 7.04909 22.5931C8.0719 22.5931 9.0256 22.331 9.86872 21.8897C9.91019 21.8621 9.96547 21.8483 10.0069 21.8207C10.0208 21.8069 10.0346 21.8069 10.0484 21.7931C11.1818 21.131 12.0802 20.1104 12.5778 18.8828C13.7112 16.6207 13.8908 14.1379 15.1901 13.2552C17.3463 11.7517 21.3131 14.6483 25.1003 12.2621Z"
                        fill="#080108" />
                    <path
                        d="M42.7782 16.6207C42.7782 13.3242 40.1106 10.6483 36.8211 10.6483C35.2454 10.6483 33.8079 11.269 32.7437 12.2621C29.9655 14.7862 30.394 18.9104 28.4589 20.138C27.1182 20.9794 25.0035 20.1931 22.6676 20.1104C22.405 20.0828 22.1424 20.0552 21.8798 20.0552C18.5902 20.0552 15.9226 22.7311 15.9226 26.0276C15.9226 29.3242 18.5902 32 21.8798 32C22.8335 32 23.7319 31.7794 24.5335 31.3794C24.6441 31.338 24.7547 31.2966 24.8514 31.2276C25.045 31.1035 25.2246 30.9794 25.3905 30.8414C26.1507 30.2759 26.7865 29.5449 27.2011 28.6897C28.528 26.3311 28.6524 23.6 30.0346 22.6621C31.7623 21.4897 34.5681 23.0069 37.5122 22.5793C40.47 22.2345 42.7644 19.7104 42.7644 16.6483C42.7644 16.6345 42.7644 16.6345 42.7644 16.6207C42.7782 16.6345 42.7782 16.6207 42.7782 16.6207Z"
                        fill="#080108" />
                    <path
                        d="M53.9324 40.4138V29.3793H56.1991V30.9104C56.3788 30.6345 56.6138 30.3724 56.9179 30.138C57.1805 29.9449 57.5122 29.7655 57.9269 29.6138C58.3277 29.4621 58.8529 29.3793 59.4749 29.3793C60.0692 29.3793 60.5668 29.4759 60.9815 29.6552C61.3823 29.8483 61.714 30.0552 61.9628 30.2759C62.2116 30.5104 62.3913 30.7173 62.5018 30.9242C62.6124 31.1311 62.6677 31.2552 62.6677 31.2966C62.6677 31.338 62.723 31.269 62.8336 31.0621C62.9441 30.869 63.1376 30.6483 63.4141 30.4C63.6905 30.1517 64.0499 29.9173 64.506 29.6966C64.9621 29.4759 65.5426 29.3655 66.2475 29.3655C66.9524 29.3655 67.533 29.4897 68.0029 29.738C68.459 29.9862 68.8322 30.2897 69.1225 30.6345C69.3989 30.9793 69.6062 31.3242 69.7168 31.669C69.8412 32.0138 69.8965 32.2897 69.8965 32.4828V40.4138H67.6297V33.5173C67.6297 33.338 67.5744 33.1448 67.45 32.9517C67.3256 32.7586 67.1736 32.5793 66.9939 32.4276C66.8004 32.2759 66.5931 32.1517 66.3443 32.0414C66.0955 31.9311 65.8467 31.8897 65.5841 31.8897C64.8239 31.8897 64.1881 32.1104 63.7043 32.5517C63.2206 32.9931 62.9718 33.6552 62.9718 34.538V40.4138H60.705V33.5586C60.705 33.1311 60.553 32.7448 60.2627 32.4C59.9725 32.0552 59.544 31.8759 58.9911 31.8759C58.6179 31.8759 58.2586 31.9311 57.9269 32.0414C57.5813 32.1517 57.2911 32.3173 57.0284 32.538C56.7658 32.7586 56.5585 33.0345 56.4065 33.3655C56.2544 33.6966 56.1715 34.0828 56.1715 34.5242V40.4138H53.9324Z"
                        fill="#080108" />
                    <path
                        d="M77.1115 29.3793C80.9263 29.3793 82.2808 32.6345 81.9352 35.5862H74.043C74.513 38.8138 78.853 38.6345 80.9263 37.2276V39.5173C70.712 44.138 68.0029 29.3793 77.1115 29.3793ZM79.6961 33.7655C79.5856 30.3862 74.2504 30.6345 74.043 33.7655H79.6961Z"
                        fill="#080108" />
                    <path
                        d="M90.1453 31.9172H87.6574V40.4138H85.3353V31.9172H83.58V29.6552H85.3353V25.7793L87.6574 24.9655V29.6552H90.1453V31.9172Z"
                        fill="#080108" />
                    <path
                        d="M102.405 29.3793V40.3448H100.166V38.869C96.835 42.1931 91.2096 39.7931 91.2096 34.9655C91.2096 30.2069 96.9733 27.669 100.166 30.8552V29.3793H102.405ZM96.7936 38.4C101.203 38.3862 101.203 31.6 96.7936 31.5862C92.3568 31.6138 92.3568 38.3724 96.7936 38.4Z"
                        fill="#080108" />
                    <path d="M109.51 40.4138L103.967 29.3793H106.662L109.51 35.1862L112.371 29.3793H115.066L109.51 40.4138Z"
                        fill="#080108" />
                    <path
                        d="M127.906 29.3793H130.173V30.7448C130.367 30.4966 130.643 30.2621 131.002 30.0552C131.306 29.8897 131.707 29.738 132.205 29.5862C132.702 29.4345 133.324 29.3655 134.071 29.3655V31.6276C133.698 31.6276 133.283 31.669 132.827 31.738C132.371 31.8069 131.942 31.9173 131.555 32.0828C131.154 32.2483 130.836 32.4552 130.574 32.7173C130.311 32.9793 130.173 33.3104 130.173 33.6966V40.4138H127.906V29.3793Z"
                        fill="#080108" />
                    <path
                        d="M139.212 29.3793C141.272 29.3793 141.839 29.7655 142.751 30.2897L141.659 31.7655C139.682 30.5793 137.734 31.2 137.734 32.4552C137.734 34.1242 143.235 33.7104 143.235 36.6207C143.235 40.1655 138.632 41.8345 135.301 38.9104L136.407 37.2276C137.789 38.7173 140.995 38.9242 140.995 36.8414C140.995 35.1724 135.494 35.7931 135.494 32.4552C135.494 31.4069 136.738 29.3793 139.212 29.3793Z"
                        fill="#080108" />
                    <path
                        d="M120.94 29.3793C124.755 29.3793 126.109 32.6345 125.764 35.5862H117.872C118.342 38.8138 122.682 38.6345 124.755 37.2276V39.5173C114.541 44.138 111.818 29.3793 120.94 29.3793ZM123.525 33.7655C123.414 30.3862 118.079 30.6345 117.872 33.7655H123.525Z"
                        fill="#080108" />
                    <path
                        d="M149.993 29.3793C153.808 29.3793 155.163 32.6345 154.817 35.5862H146.925C147.395 38.8138 151.735 38.6345 153.808 37.2276V39.5173C143.58 44.138 140.871 29.3793 149.993 29.3793ZM152.578 33.7655C152.467 30.3862 147.132 30.6345 146.925 33.7655H152.578Z"
                        fill="#080108" />
                    <path
                        d="M53.9324 22.7586V11.7241H56.1991V13.2552C56.3788 12.9793 56.6138 12.7172 56.9179 12.4827C57.1805 12.2896 57.5122 12.1103 57.9269 11.9586C58.3277 11.7931 58.8529 11.7241 59.4749 11.7241C60.0692 11.7241 60.5668 11.8207 60.9815 12C61.3823 12.1931 61.714 12.4 61.9628 12.6207C62.2116 12.8552 62.3913 13.0621 62.5018 13.2689C62.6124 13.4758 62.6677 13.6 62.6677 13.6414C62.6677 13.6827 62.723 13.6138 62.8336 13.4069C62.9441 13.2138 63.1376 12.9931 63.4141 12.7448C63.6905 12.4965 64.0499 12.2621 64.506 12.0414C64.9621 11.8207 65.5426 11.7103 66.2475 11.7103C66.9524 11.7103 67.533 11.8345 68.0029 12.0827C68.459 12.331 68.8322 12.6345 69.1225 12.9793C69.3989 13.3241 69.6062 13.6689 69.7168 14.0138C69.8412 14.3586 69.8965 14.6345 69.8965 14.8276V22.7586H67.6297V15.8621C67.6297 15.6827 67.5744 15.4896 67.45 15.2965C67.3256 15.1034 67.1736 14.9241 66.9939 14.7724C66.8004 14.6207 66.5931 14.4965 66.3443 14.3862C66.0955 14.2758 65.8467 14.2345 65.5841 14.2345C64.8239 14.2345 64.1881 14.4552 63.7043 14.8965C63.2206 15.3379 62.9718 16 62.9718 16.8827V22.7586H60.705V15.9034C60.705 15.4758 60.553 15.0896 60.2627 14.7448C59.9725 14.4 59.544 14.2207 58.9911 14.2207C58.6179 14.2207 58.2586 14.2758 57.9269 14.3862C57.5813 14.4965 57.2911 14.6621 57.0284 14.8827C56.7658 15.1034 56.5585 15.3793 56.4065 15.7103C56.2544 16.0414 56.1715 16.4276 56.1715 16.8689V22.7586H53.9324Z"
                        fill="#080108" />
                    <path
                        d="M77.1806 11.7241C77.9408 11.7241 78.6595 11.8758 79.323 12.1655C80.0002 12.4552 80.5807 12.8552 81.0783 13.3517C81.5759 13.8483 81.9767 14.4414 82.267 15.1172C82.5572 15.7931 82.7093 16.5103 82.7093 17.2689C82.7093 18.0276 82.5572 18.7448 82.267 19.4069C81.9767 20.0827 81.5759 20.6621 81.0783 21.1586C80.5807 21.6552 80.0002 22.0552 79.323 22.3448C78.6457 22.6345 77.9408 22.7862 77.1806 22.7862C76.4204 22.7862 75.7017 22.6345 75.0382 22.3448C74.3609 22.0552 73.7804 21.6552 73.269 21.1586C72.7576 20.6621 72.3706 20.0827 72.0804 19.4069C71.7901 18.731 71.6381 18.0276 71.6381 17.2689C71.6381 16.5103 71.7901 15.7931 72.0804 15.1172C72.3706 14.4414 72.7714 13.8483 73.269 13.3517C73.7804 12.8552 74.3609 12.4552 75.0382 12.1655C75.7017 11.8758 76.4204 11.7241 77.1806 11.7241ZM77.1806 20.6069C77.6505 20.6069 78.0928 20.5241 78.4936 20.3448C78.9083 20.1655 79.2538 19.931 79.5579 19.6276C79.862 19.3241 80.1108 18.9655 80.2905 18.5655C80.4702 18.1655 80.5669 17.7241 80.5669 17.2552C80.5669 16.7862 80.4702 16.3448 80.2905 15.9448C80.1108 15.5448 79.862 15.1862 79.5579 14.8827C79.2538 14.5793 78.8945 14.331 78.4936 14.1517C78.079 13.9724 77.6505 13.8896 77.1806 13.8896C76.7106 13.8896 76.2683 13.9862 75.8675 14.1517C75.4529 14.331 75.1073 14.5793 74.8032 14.8827C74.4992 15.1862 74.2504 15.5448 74.0707 15.9448C73.891 16.3586 73.8081 16.7862 73.8081 17.2552C73.8081 17.7241 73.891 18.1655 74.0707 18.5655C74.2504 18.9793 74.4853 19.3241 74.8032 19.6276C75.1073 19.931 75.4667 20.1655 75.8675 20.3448C76.2683 20.5241 76.7106 20.6069 77.1806 20.6069Z"
                        fill="#080108" />
                    <path
                        d="M84.6443 11.7241H86.911V13.0896C87.1046 12.8414 87.381 12.6069 87.7404 12.4C88.0444 12.2345 88.4453 12.0827 88.9428 11.931C89.4404 11.7793 90.0624 11.7103 90.8088 11.7103V13.9724C90.4356 13.9724 90.0209 14.0138 89.5648 14.0827C89.1087 14.1517 88.6802 14.2621 88.2932 14.4276C87.8924 14.5931 87.5745 14.8 87.3119 15.0621C87.0493 15.3241 86.911 15.6552 86.911 16.0414V22.7586H84.6443V11.7241Z"
                        fill="#080108" />
                    <path
                        d="M97.5124 11.7241C101.327 11.7241 102.682 14.9793 102.336 17.931H94.4439C94.9139 21.1586 99.2539 20.9793 101.327 19.5724V21.8621C91.1129 26.4827 88.39 11.7241 97.5124 11.7241ZM100.097 16.1103C99.9865 12.731 94.6513 12.9793 94.4439 16.1103H100.097Z"
                        fill="#080108" />
                </svg>
            </Link>
            <Link to="/" className="md:hidden">
                <svg width="105" height="35" viewBox="0 0 105 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1298_357)">
                        <path
                            d="M4.71666 27.9719C6.93112 27.9719 8.7263 26.1721 8.7263 23.952C8.7263 21.7319 6.93112 19.9321 4.71666 19.9321C2.50221 19.9321 0.707031 21.7319 0.707031 23.952C0.707031 26.1721 2.50221 27.9719 4.71666 27.9719Z"
                            fill="#3772FF" />
                        <path
                            d="M24.7834 19.8857C23.788 19.8857 22.867 20.2478 22.1693 20.8605C20.1598 22.5502 20.4854 25.4375 19.1551 26.273C18.2434 26.8486 16.7921 26.3009 15.2013 26.2544C15.0431 26.2359 14.8943 26.2266 14.7268 26.2266C12.5127 26.2266 10.7172 28.0277 10.7172 30.2465C10.7172 32.4653 12.5127 34.2664 14.7268 34.2664C15.378 34.2664 16.0013 34.1086 16.5409 33.83C16.606 33.8022 16.6711 33.7743 16.727 33.7372C16.8107 33.6815 16.8851 33.6351 16.9595 33.5794C17.5549 33.1802 18.0294 32.6324 18.3364 31.9826C19.2016 30.4043 19.2946 28.6032 20.2063 27.9812C21.6948 26.9693 24.3555 28.9282 26.9045 27.3221C26.9325 27.3035 26.9511 27.2849 26.979 27.2664C28.0674 26.5515 28.7838 25.3168 28.7838 23.9149C28.7931 21.6868 26.9976 19.8857 24.7834 19.8857Z"
                            fill="#080108" />
                        <path
                            d="M16.8944 8.25323C16.9223 8.23466 16.9409 8.21609 16.9688 8.19753C18.0573 7.48267 18.7736 6.24792 18.7736 4.84607C18.7736 2.62723 16.9781 0.826172 14.764 0.826172H14.7547H14.7454C13.4616 0.826172 12.3266 1.42962 11.5916 2.36729C10.1962 4.07551 10.3264 6.46145 9.14492 7.21344C8.23322 7.78904 6.80054 7.24129 5.21901 7.19487C5.06086 7.1763 4.90271 7.16702 4.74456 7.16702C3.95379 7.16702 3.20955 7.39912 2.59554 7.79832C2.54903 7.82617 2.50251 7.85402 2.456 7.88188C2.40948 7.90973 2.37227 7.93758 2.33506 7.97471C1.35823 8.70813 0.734924 9.86861 0.734924 11.1869C0.734924 13.4057 2.53042 15.2068 4.74456 15.2068C5.43298 15.2068 6.0749 15.0304 6.64239 14.7333C6.6703 14.7148 6.70751 14.7055 6.73542 14.6869C6.74472 14.6776 6.75402 14.6776 6.76333 14.6683C7.52618 14.2227 8.13088 13.5357 8.46579 12.7095C9.22865 11.1869 9.34959 9.51583 10.2241 8.92166C11.6754 7.90973 14.3453 9.85933 16.8944 8.25323Z"
                            fill="#080108" />
                        <path
                            d="M28.7931 11.1869C28.7931 8.96805 26.9976 7.16699 24.7834 7.16699C23.7229 7.16699 22.7554 7.58476 22.039 8.2532C20.1691 9.95214 20.4575 12.728 19.1551 13.5543C18.2527 14.1206 16.8293 13.5914 15.2571 13.5357C15.0803 13.5171 14.9036 13.4986 14.7268 13.4986C12.5127 13.4986 10.7172 15.2996 10.7172 17.5185C10.7172 19.7373 12.5127 21.5383 14.7268 21.5383C15.3687 21.5383 15.9734 21.3898 16.513 21.1206C16.5874 21.0927 16.6618 21.0649 16.727 21.0185C16.8572 20.9349 16.9781 20.8513 17.0898 20.7585C17.6015 20.3779 18.0294 19.8858 18.3085 19.3102C19.2016 17.7227 19.2853 15.8845 20.2156 15.2532C21.3785 14.4641 23.267 15.4853 25.2486 15.1975C27.2395 14.9654 28.7838 13.2665 28.7838 11.2055C28.7838 11.1962 28.7838 11.1962 28.7838 11.1869C28.7931 11.1962 28.7931 11.1869 28.7931 11.1869Z"
                            fill="#080108" />
                        <path
                            d="M36.3007 27.2015V19.7744H37.8264V20.8049C37.9473 20.6192 38.1055 20.4429 38.3101 20.285C38.4869 20.1551 38.7102 20.0344 38.9893 19.9322C39.259 19.8301 39.6126 19.7744 40.0312 19.7744C40.4312 19.7744 40.7661 19.8394 41.0452 19.9601C41.315 20.0901 41.5383 20.2293 41.7058 20.3779C41.8732 20.5357 41.9942 20.675 42.0686 20.8142C42.143 20.9535 42.1802 21.037 42.1802 21.0649C42.1802 21.0927 42.2174 21.0463 42.2918 20.907C42.3663 20.7771 42.4965 20.6285 42.6826 20.4614C42.8686 20.2943 43.1105 20.1365 43.4175 19.9879C43.7245 19.8394 44.1153 19.7651 44.5897 19.7651C45.0642 19.7651 45.4549 19.8487 45.7712 20.0158C46.0782 20.1829 46.3294 20.3872 46.5248 20.6192C46.7108 20.8513 46.8504 21.0834 46.9248 21.3155C47.0085 21.5476 47.0457 21.7333 47.0457 21.8633V27.2015H45.52V22.5596C45.52 22.4389 45.4828 22.3089 45.3991 22.1789C45.3154 22.049 45.213 21.9283 45.0921 21.8261C44.9618 21.724 44.8223 21.6405 44.6548 21.5662C44.4874 21.4919 44.3199 21.4641 44.1432 21.4641C43.6315 21.4641 43.2036 21.6126 42.8779 21.9097C42.5523 22.2068 42.3849 22.6524 42.3849 23.2466V27.2015H40.8592V22.5874C40.8592 22.2996 40.7568 22.0397 40.5615 21.8076C40.3661 21.5755 40.0777 21.4548 39.7056 21.4548C39.4544 21.4548 39.2125 21.4919 38.9893 21.5662C38.7567 21.6405 38.5613 21.7519 38.3846 21.9004C38.2078 22.049 38.0682 22.2346 37.9659 22.4574C37.8636 22.6803 37.8078 22.9402 37.8078 23.2373V27.2015H36.3007Z"
                            fill="#080108" />
                        <path
                            d="M51.9019 19.7744C54.4696 19.7744 55.3813 21.9654 55.1487 23.9521H49.8366C50.153 26.1245 53.0741 26.0039 54.4696 25.0569V26.598C47.5946 29.7081 45.7712 19.7744 51.9019 19.7744ZM53.6416 22.7267C53.5672 20.4521 49.9762 20.6192 49.8366 22.7267H53.6416Z"
                            fill="#080108" />
                        <path
                            d="M60.6747 21.4828H59.0001V27.2016H57.4372V21.4828H56.2557V19.9602H57.4372V17.3515L59.0001 16.8037V19.9602H60.6747V21.4828Z"
                            fill="#080108" />
                        <path
                            d="M68.9266 19.7744V27.1551H67.4195V26.1617C65.1775 28.3991 61.3911 26.7837 61.3911 23.5344C61.3911 20.3314 65.2705 18.6232 67.4195 20.7678V19.7744H68.9266ZM65.1496 25.846C68.1172 25.8367 68.1172 21.2691 65.1496 21.2598C62.1633 21.2784 62.1633 25.8275 65.1496 25.846Z"
                            fill="#080108" />
                        <path d="M73.7084 27.2015L69.9779 19.7744H71.792L73.7084 23.6829L75.6342 19.7744H77.4483L73.7084 27.2015Z"
                            fill="#080108" />
                        <path
                            d="M86.0908 19.7744H87.6165V20.6935C87.7468 20.5264 87.9328 20.3686 88.1747 20.2293C88.3794 20.1179 88.6492 20.0158 88.9841 19.9137C89.319 19.8116 89.7376 19.7651 90.24 19.7651V21.2877C89.9888 21.2877 89.7097 21.3155 89.4027 21.362C89.0957 21.4084 88.8073 21.4826 88.5468 21.5941C88.277 21.7055 88.0631 21.8447 87.8863 22.0211C87.7096 22.1975 87.6165 22.4203 87.6165 22.6803V27.2015H86.0908V19.7744Z"
                            fill="#080108" />
                        <path
                            d="M93.7008 19.7744C95.0869 19.7744 95.4684 20.0344 96.0824 20.3871L95.3474 21.3805C94.0171 20.5821 92.7053 20.9999 92.7053 21.8447C92.7053 22.968 96.408 22.6895 96.408 24.6484C96.408 27.0344 93.31 28.1577 91.068 26.1895L91.8122 25.0569C92.7425 26.0596 94.9009 26.1988 94.9009 24.797C94.9009 23.6736 91.1982 24.0914 91.1982 21.8447C91.1982 21.1391 92.0355 19.7744 93.7008 19.7744Z"
                            fill="#080108" />
                        <path
                            d="M81.402 19.7744C83.9697 19.7744 84.8814 21.9654 84.6488 23.9521H79.3368C79.6531 26.1245 82.5742 26.0039 83.9697 25.0569V26.598C77.0947 29.7081 75.262 19.7744 81.402 19.7744ZM83.1417 22.7267C83.0673 20.4521 79.4763 20.6192 79.3368 22.7267H83.1417Z"
                            fill="#080108" />
                        <path
                            d="M100.957 19.7744C103.525 19.7744 104.436 21.9654 104.204 23.9521H98.8918C99.2081 26.1245 102.129 26.0039 103.525 25.0569V26.598C96.6405 29.7081 94.8171 19.7744 100.957 19.7744ZM102.697 22.7267C102.622 20.4521 99.0314 20.6192 98.8918 22.7267H102.697Z"
                            fill="#080108" />
                        <path
                            d="M36.3007 15.3182V7.89112H37.8264V8.92162C37.9473 8.73595 38.1055 8.55955 38.3101 8.40173C38.4869 8.27176 38.7102 8.15107 38.9893 8.04894C39.259 7.93754 39.6126 7.89112 40.0312 7.89112C40.4312 7.89112 40.7661 7.95611 41.0452 8.0768C41.315 8.20677 41.5383 8.34603 41.7058 8.49457C41.8732 8.65239 41.9942 8.79165 42.0686 8.93091C42.143 9.07016 42.1802 9.15372 42.1802 9.18157C42.1802 9.20942 42.2174 9.163 42.2918 9.02375C42.3663 8.89377 42.4965 8.74523 42.6826 8.57812C42.8686 8.41101 43.1105 8.25319 43.4175 8.10465C43.7245 7.95611 44.1153 7.88184 44.5897 7.88184C45.0642 7.88184 45.4549 7.96539 45.7712 8.1325C46.0782 8.29961 46.3294 8.50385 46.5248 8.73595C46.7108 8.96804 46.8504 9.20014 46.9248 9.43223C47.0085 9.66433 47.0457 9.85001 47.0457 9.97998V15.3182H45.52V10.6763C45.52 10.5556 45.4828 10.4256 45.3991 10.2956C45.3154 10.1657 45.213 10.045 45.0921 9.94284C44.9618 9.84072 44.8223 9.75717 44.6548 9.6829C44.4874 9.60863 44.3199 9.58077 44.1432 9.58077C43.6315 9.58077 43.2036 9.72932 42.8779 10.0264C42.5523 10.3235 42.3849 10.7691 42.3849 11.3633V15.3182H40.8592V10.7041C40.8592 10.4163 40.7568 10.1564 40.5615 9.92428C40.3661 9.69218 40.0777 9.57149 39.7056 9.57149C39.4544 9.57149 39.2125 9.60863 38.9893 9.6829C38.7567 9.75717 38.5613 9.86857 38.3846 10.0171C38.2078 10.1657 38.0682 10.3513 37.9659 10.5741C37.8636 10.797 37.8078 11.0569 37.8078 11.354V15.3182H36.3007Z"
                            fill="#080108" />
                        <path
                            d="M51.9484 7.89111C52.4601 7.89111 52.9439 7.99324 53.3904 8.1882C53.8463 8.38316 54.237 8.65239 54.5719 8.9866C54.9068 9.32082 55.1766 9.72003 55.372 10.1749C55.5673 10.6298 55.6697 11.1126 55.6697 11.6232C55.6697 12.1338 55.5673 12.6166 55.372 13.0622C55.1766 13.5171 54.9068 13.907 54.5719 14.2412C54.237 14.5755 53.8463 14.8447 53.3904 15.0397C52.9346 15.2346 52.4601 15.3367 51.9484 15.3367C51.4368 15.3367 50.953 15.2346 50.5065 15.0397C50.0506 14.8447 49.6599 14.5755 49.3157 14.2412C48.9714 13.907 48.711 13.5171 48.5156 13.0622C48.3202 12.6073 48.2179 12.1338 48.2179 11.6232C48.2179 11.1126 48.3202 10.6298 48.5156 10.1749C48.711 9.72003 48.9807 9.32082 49.3157 8.9866C49.6599 8.65239 50.0506 8.38316 50.5065 8.1882C50.953 7.99324 51.4368 7.89111 51.9484 7.89111ZM51.9484 13.8699C52.2647 13.8699 52.5624 13.8142 52.8322 13.6935C53.1113 13.5728 53.3439 13.415 53.5486 13.2107C53.7532 13.0065 53.9207 12.7651 54.0416 12.4959C54.1626 12.2267 54.2277 11.9296 54.2277 11.6139C54.2277 11.2983 54.1626 11.0012 54.0416 10.732C53.9207 10.4627 53.7532 10.2214 53.5486 10.0171C53.3439 9.81286 53.102 9.64576 52.8322 9.52507C52.5531 9.40438 52.2647 9.34867 51.9484 9.34867C51.6321 9.34867 51.3344 9.41366 51.0646 9.52507C50.7856 9.64576 50.553 9.81286 50.3483 10.0171C50.1436 10.2214 49.9762 10.4627 49.8552 10.732C49.7343 11.0105 49.6785 11.2983 49.6785 11.6139C49.6785 11.9296 49.7343 12.2267 49.8552 12.4959C49.9762 12.7744 50.1343 13.0065 50.3483 13.2107C50.553 13.415 50.7949 13.5728 51.0646 13.6935C51.3344 13.8142 51.6321 13.8699 51.9484 13.8699Z"
                            fill="#080108" />
                        <path
                            d="M56.9722 7.89112H58.4979V8.81022C58.6281 8.64311 58.8142 8.48528 59.0561 8.34603C59.2607 8.23462 59.5305 8.1325 59.8654 8.03038C60.2003 7.92825 60.619 7.88184 61.1213 7.88184V9.40438C60.8702 9.40438 60.5911 9.43223 60.2841 9.47865C59.9771 9.52507 59.6887 9.59934 59.4282 9.71075C59.1584 9.82215 58.9444 9.96141 58.7677 10.1378C58.5909 10.3142 58.4979 10.537 58.4979 10.797V15.3182H56.9722V7.89112Z"
                            fill="#080108" />
                        <path
                            d="M65.6332 7.89111C68.2009 7.89111 69.1126 10.0821 68.88 12.0688H63.568C63.8843 14.2412 66.8054 14.1206 68.2009 13.1736V14.7147C61.3259 17.8248 59.4932 7.89111 65.6332 7.89111ZM67.3729 10.8434C67.2985 8.56883 63.7075 8.73594 63.568 10.8434H67.3729Z"
                            fill="#080108" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1298_357">
                            <rect width="104.613" height="35" fill="white" />
                        </clipPath>
                    </defs>
                </svg>

            </Link>
        </div>
    );
}