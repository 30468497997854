import { useQuery } from "@apollo/client";
import { GetBlogPostBySlug, getHomePageBlogPosts } from "../queries/get-blogposts";
import { Link, useParams } from "react-router-dom";
import { BlogPostType } from "./BlogImportantPosts";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Carousel } from "../shared/Carousel";
import { useTitle } from "react-use";

export const Post = () => {
    const { slug } = useParams();
    const { data } = useQuery<{ BlogPost: BlogPostType }>(GetBlogPostBySlug, {
        variables: { slug: slug }
    });

    useTitle('More Metaverse | Blog');

    return (
        <div className="max-w-off mx-auto pt-pcustom41 border-t border-t-text">
            <div className="px-5 md:px-0">
                <img
                    className="aspect-[320/247] w-full rounded-3xl bg-gray-100 object-cover md:aspect-[1200/457] md:max-h-[224px] md:max-h-[457px]"
                    src={data?.BlogPost !== null ? data?.BlogPost?.cover_image.url : 'https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4'}
                    alt="post image"
                />
                <div className="mx-auto md:w-2/3 md:px-1">
                    <header className="pt-7 pb-pcustom30 md:py-10">
                        <p
                            className="text-sm md:text-lg leading-leading182 md:leading-leading234">{data?.BlogPost?.category}</p>
                        <h2
                            className="pt-3 pb-pcustom18 md:pt-6 md:pb-pcustom26 text-technologiesMobile md:text-5xl leading-leading364 md:leading-leading624 font-semibold font-serif">
                            {data?.BlogPost.title}</h2>
                        <div
                            className="flex justify-between text-sm md:text-base leading-leading182 md:leading-leading272 md:tracking-small">
                            {/* <p>{data?.Post?.createdat}</p> */}
                            {/* <p>By <span
                                className="font-semibold md:tracking-small2">{data?.Post?.relationauthor?.map((elem) => (elem?.name))}</span>
                            </p> */}
                        </div>
                    </header>
                    <main
                        className="hidden md:block md:pt-0 text-base md:text-lg leading-leading272 md:leading-leading306 tracking-small md:tracking-small2">
                        {data?.BlogPost.content.map((contentPiece, index) => {
                            if (contentPiece.body.startsWith('<p>')) {
                                return <p key={index} className="mb-10">
                                    {contentPiece.text}
                                </p>
                            } else if (contentPiece.body.startsWith('<h4>')) {
                                return <h3 key={index} className="text-xl font-bold my-5">
                                    {contentPiece.text}
                                </h3>
                            }
                        })}
                    </main>
                    <main
                        className=" md:hidden md:pt-11 text-base md:text-lg leading-leading272 md:leading-leading306 tracking-small md:tracking-small2">
                        {data?.BlogPost.content.map((contentPiece, index) => {
                            if (contentPiece.body.startsWith('<p>')) {
                                return <p key={index} className="mb-10">
                                    {contentPiece.text}
                                </p>
                            } else if (contentPiece.body.startsWith('<h4>')) {
                                return <h3 key={index} className="text-2xl my-5">
                                    {contentPiece.text}
                                </h3>
                            }
                        })}
                    </main>
                    <section className="flex flex-col md:flex-row justify-end py-10 md:pb-20 md:mb-2.5 md:pt-[72px]">
                        <Link to={'/blog'}
                            className="md:self-end text-lg leading-leading234 text-active md:text-text hover:text-active cursor-pointer flex items-center"><ChevronLeftIcon className="w-6 h-6" />To the blog list</Link>
                    </section>
                </div>
                <div className="border-t border-t-text pt-pcustom30 md:pt-pcustom60 md:pb-14">
                    <h3 className="pb-10 font-semibold text-font32 font-serif leading-leading416 w-2/3 md:w-full">
                        This may interest you
                    </h3>
                    <section>
                        <RecentPosts slugId={slug}/>
                    </section>
                </div>
            </div>
        </div>
    );
};

const slugs = ['metaverse-explained', 'powering-the-future-of-digital-art', 'why-do-we-plant-one-tree-for-every-nft-sold'];

type Props = {
    slugId: string | undefined;
};

const RecentPosts = ({slugId}: Props) => {
    const { loading, error, data } = useQuery<{ [key: string]: { _slug: string, title: string, category: string, cover_image: { url: string } } }>(getHomePageBlogPosts, {
        variables: { slug1: slugs[2], slug2: slugs[1], slug3: slugs[0] }
    });

    const blogPosts = [];
    if (data) {
        for (const key in data)
        {
            if(data[key]._slug !== slugId){
                blogPosts.push(data[key]);
            }
        }

    }

    return (
        <>
            <div className="hidden md:inline-grid md:grid-cols-3 md:gap-5">
                {blogPosts?.map((post, index) => {
                    return (
                        <Link key={index} to={`/blog/${post?._slug}`}>
                            <div className="md:pb-pcustom45 hover:cursor-pointer">
                                <img
                                    src={post?.cover_image !== null ? post?.cover_image.url : "https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                                    alt=""
                                    className="aspect-[16/9] w-full rounded-3xl bg-gray-100 object-cover md:aspect-[4/3]"
                                />
                                <div className="md:pt-7 ">
                                    <div className="md:pb-3 md:text-sm md:leading-leading182">
                                        <p>{post?.category}</p></div>
                                    <h2
                                        className="font-serif font-semibold md:text-technologiesMobile md:leading-leading364 hover:text-active">{post?.title}</h2>
                                </div>
                            </div>
                        </Link>
                    )
                })}
            </div>
            <section className="md:hidden flex pb-12">
                <Carousel>
                    {blogPosts?.map((post, index) => (
                        <Link key={index} to={`/blog/${post?._slug}`}>
                            <div className="w-[calc(100vw_-_100px)] pl-5">
                                <img
                                    src={post?.cover_image !== null ? post?.cover_image?.url : "https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                                    alt={post?.title}
                                    className="aspect-[4/3] rounded-3xl bg-gray-100 object-cover" />
                                <div className="pt-5 md:pt-7">
                                    <div
                                        className="pb-3 text-sm leading-leading182">{post?.category}</div>
                                    <h2
                                        className="font-serif max-w-full font-semibold text-technologiesMobile leading-leading364 hover:text-active">{post?.title}</h2>
                                </div>
                            </div>
                        </Link>
                    ))}
                </Carousel>
            </section>
        </>
    );
};