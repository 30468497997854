import { useRef, useState } from 'react';
// @ts-ignore
import emailjs from '@emailjs/browser';

const contactForm = {
    button: 'Get in touch',
    buttonOnLoad: 'Sending...',
    newsletter: 'Please keep me informed about More Metaverse services, offerings and upcoming events.',
    policy: 'By submitting this form I agree to More Metaverse Privacy Policy and Terms of use.',
    placeholders: {
        name: 'Name and surname',
        email: 'Email',
    }
}

export const ContactForm = () => {
    const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
    const [isPolicyChecked, setIsPolicyChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useRef<HTMLFormElement>(null);

    const checkNewsletterHandler = () => {
        setIsNewsletterChecked(!isNewsletterChecked)
    }
    const checkPolicyHandler = () => {
        setIsPolicyChecked(!isPolicyChecked)
    }


    const sendEmail = (e: any) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID!, process.env.REACT_APP_CONTACT_CAREERS_TEMPLATE_ID!, form.current!, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
            .then((_result: any) => {
                alert(`You have requested a demo. Our team will be in touch with you soon.`);
                e.target.reset();
                setIsNewsletterChecked(false);
                setIsPolicyChecked(false);
                setLoading(false)
            }, (_error: any) => {
                alert(`There was some problems. Please try again later.`)
            });
    };

    return (
        <form
            ref={form}
            onSubmit={sendEmail}
            className="flex flex-col md:ml-10 md:ml-20 mt-2"
        >
            <label
                htmlFor="from_name"
                className="sr-only"
            >
                {contactForm.placeholders.name}
            </label>
            <input
                type="text"
                id="from_name"
                name="from_name"
                autoComplete={"off"}
                minLength={3}
                required={true}
                placeholder={contactForm.placeholders.name}
                className="block w-full outline-none appearance-none focus:outline-none border-0 border-b border-b-text py-pcustom7 bg-background font-normal font-sans placeholder:font-sans placeholder:font-normal placeholder:bg-background placeholder:text-text placeholder:opacity-40 md:leading-7"
            />
            <label
                htmlFor="from_email"
                className="sr-only"
            >
                {contactForm.placeholders.email}
            </label>
            <input
                type="email"
                name="from_email"
                id="from_email"
                autoComplete={"off"}
                required={true}
                placeholder={contactForm.placeholders.email}
                className="mt-5 mb-4 md:mb-3.5 appearance-none focus:ring-0 outline-0 focus:outline-none block w-full border-0 border-b border-b-text py-pcustom7 bg-background font-normal font-sans placeholder:font-sans placeholder:font-normal placeholder:bg-background placeholder:text-text placeholder:opacity-40 md:leading-7"
            />
            <div className='flex gap-2 appearance-none'>
                <input
                    checked={isNewsletterChecked}
                    onChange={checkNewsletterHandler}
                    type="checkbox"
                    id="newsletter"
                    name="newsletter"
                    className="
                    relative peer shrink-0
                    appearance-none w-4 h-4 border border-text rounded bg-background
                    mt-4
                    "
                />
                <label
                    htmlFor="newsletter"
                    className="text-font14 font-light leading-leading224 tracking-small2 pt-3.5"
                >
                    {contactForm.newsletter}
                </label>
                <svg
                    className="
                    absolute 
                    w-3 h-3 mt-m18 ml-0.5
                    hidden peer-checked:block
                    pointer-events-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            </div>
            <div className='flex gap-2 appearance-none'>
                <input
                    checked={isPolicyChecked}
                    onChange={checkPolicyHandler}
                    type="checkbox"
                    id="policy"
                    name="policy"
                    required={true}
                    className="
                    relative peer shrink-0
                    appearance-none w-4 h-4 border border-text rounded bg-background
                    mt-2.5
                    "
                />
                <label
                    htmlFor="policy"
                    className="text-font14 font-light leading-leading224 tracking-small2 pt-2"
                >
                    {contactForm.policy}
                </label>
                <svg
                    className="
                    absolute 
                    w-3 h-3 mt-3 ml-0.5
                    hidden peer-checked:block
                    pointer-events-none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
            </div>
            <input type="text" name="contact" value="contact" className='hidden'/>
            <button type="submit"
                className="w-full max-w-[386px] mx-auto md:w-1/2 md:w-full bg-text text-whitespace rounded-xl py-3.5 mt-7 md:mt-m34 text-font22 font-semibold leading-leading286 duration-500 hover:bg-active">
                {loading === true ?
                    (
                        <p className="cursor-not-allowed">{contactForm.buttonOnLoad}</p>
                    ) : (
                        <p>{contactForm.button}</p>
                    )}
            </button>
        </form>
    );
};
