import { useTitle } from "react-use";
import { CareersForm } from "./CareersForm";

const careerContent = {
    header: 'Join the team that’s changing the Web3',
    content: 'Send us your CV to join our growing team that develops cutting edge blockchain solutions.'
    // content: 'Send us your CV to join our growing team that develops cutting edge blockchain solutions. Use the form or ',
    // contentEmail: 'webforms@more-metaverse.com',
};

export const Careers = () => {
    useTitle('More Metaverse | Careers');

    return (
        <div className="max-w-off mx-auto md:mb-m54 border-t border-t-text">
            <div
                className="max-w-off mx-auto grid sm:grid-cols-2 pt-10 lg:pt-pcustom120 pb-10 lx:pb-pcustom106 sm:gap-16 px-5 xl:px-0">
                <div className="pb-pcustom46">
                    <h2
                        className="font-serif text-5xl md:text-technologies font-semibold leading-leading624 md:leading-leading675">
                        {careerContent?.header}
                    </h2>
                    <p className="text-font16 leading-leading272 tracking-small pt-5 lg:pt-7 lg:pb-0 md:w-4/6">
                        {careerContent?.content}
                        {/* <span className="text-active">{careerContent?.contentEmail}</span> */}
                    </p>
                </div>
                <CareersForm />
            </div>
        </div>
    );
};
