import { Link } from "react-router-dom";

export const BlogPost = ({ blogPost }: { blogPost: {_slug: string, title: string, category: string, cover_image: {url: string}} }) => {

    return (
        <Link to={`/blog/${blogPost?._slug}`}>
            <div className="md:pb-pcustom45 hover:cursor-pointer">
                <img
                    src={blogPost?.cover_image !== null ? blogPost?.cover_image?.url : "https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                    alt={blogPost?.title}
                    className="aspect-[4/3] rounded-3xl bg-gray-100 object-cover md:aspect-[4/3] md:max-w-[230px] md:max-w-[308px] md:max-w-full"
                />
                <div className="pt-5 md:pt-7 max-w-[387px]">
                    <div
                        className="pb-2 md:pb-3 text-xs md:text-sm leading-leading156 md:leading-leading182 ">{blogPost?.category}</div>
                    <h2
                        className="font-serif font-semibold text-font22 md:text-technologiesMobile leading-leading286 md:leading-leading364 hover:text-active duration-500">
                        {blogPost?.title || 'Blog post title'}
                    </h2>
                </div>
            </div>
        </Link>
    );
};