import { gql } from "@apollo/client";

export const GetBlogposts = gql`
    query {
        BlogPosts {
            items {
                _id
                _slug
                title
                category
                cover_image {
                    url
                }
                content { 
                    __typename
                    ... on Text {
                        body
                        text
                    }
                }
            }
        }
    }
`;

export const getHomePageBlogPosts = gql`
    query ($slug1: String, $slug2: String, $slug3: String) {
        BlogPost1: BlogPost (slug: $slug1) {
            _id
            _slug
            title
            category
            cover_image {
                url
            }
            content { 
                __typename
                ... on Text {
                    body
                    text
                }
            }
        }
        BlogPost2: BlogPost (slug: $slug2) {
            _id
            _slug
            title
            category
            cover_image {
                url
            }
            content { 
                __typename
                ... on Text {
                    body
                    text
                }
            }
        }
        BlogPost3: BlogPost (slug: $slug3) {
            _id
            _slug
            title
            category
            cover_image {
                url
            }
            content { 
                __typename
                ... on Text {
                    body
                    text
                }
            }
        }
    }
`;

export const GetBlogPostBySlug = gql`
    query ($slug: String) {
        BlogPost (slug: $slug) {
            _slug
            title
            category
            cover_image {
                url
            }
            content {
                __typename
                ... on Text {
                    body
                    text
                }
            }
        }
    }
`;