import { Link } from "react-router-dom";
import { Brand } from "./Brand";
import { InputWithActionButton } from "./InputWithActionButton";
import cn from "classnames";

const footerContent = {
    newsletterHeader: 'Our Newsletter',
    newsletter: 'Subscribe to our newsletter and stay up to date with the latest innovations in Web3.',
    navLinks: [
        {
            title: 'Our Vision',
            path: '/our-vision'
        },
        {
            title: 'Book a demo',
            path: '/contact',
        },
        {
            title: 'Blog',
            path: '/blog'
        },
        {
            title: 'Careers',
            path: '/careers'
        },
        {
            title: 'FAQ',
            path: '/faq'
        },
        {
            title: 'Privacy Policy',
            path: '/privacy-policy'
        },
    ],
    copyrightNotice1: '© More Metaverse PSA | Rondo ONZ 1, 00-124 Warszawa, Poland | info@more-metaverse.com | Company registration number 0001041707',
    copyrightNotice2: 'UK office: 45 Fitzroy Street, London, W1T 6EB, United Kingdom | For any enquires please call +44 20 3834 8476',
};

export const Footer = () => {
    return (
        <div className={cn(
            "bg-text text-whitespace h-fit w-full pt-14 md:pt-pcustom90",
        )}>
            <div className="max-w-[1200px] mx-auto flex flex-col px-5 xl:px-0">
                <div className="grid md:grid-cols-2 grid-cols-1">
                    <div className="max-[640px]:max-w-[640px] min-[640px]:w-2/3 min-[640px]:pr-3">
                        <span
                            className="font-semibold text-3xl font-serif leading-leading364">{footerContent.newsletterHeader}</span>
                        <p className="leading-leading272 py-pcustom18 font-light">
                            {footerContent.newsletter}
                        </p>
                        <InputWithActionButton />
                    </div>
                    <div
                        className="grid md:grid-rows-3 md:grid-flow-col grid-rows-1 md:py-0 py-6 md:mt-2.5">
                        {footerContent.navLinks.map((link) => {
                            return (
                                <Link
                                    key={link.title}
                                    to={link.path}
                                    className="inline-flex items-center text-font18 leading-leading234 font-light duration-500 hover:text-active md:pl-5 pb-7 md:py-0 first:pt-[26px] md:first:pt-0 md:mb-10"
                                >
                                    {link.title}
                                </Link>
                            )
                        })}
                    </div>
                </div>
                <div className="flex flex-col gap-4 md:gap-8 md:flex-row md:justify-between pb-2 pt-1 md:pt-[35px]">
                    <Brand />
                    <div className="flex flex-col gap-1 text-left">
                    {/* max-w-mobile sm:max-w-off mx-auto grid sm:grid-cols-2 pt-10 sm:pt-pcustom120 pb-10 sm:pb-pcustom106 sm:gap-16 px-5 xl:px-0 */}
                        <p className="w-full md:self-end text-micro font-normal md:text-sm md:font-light leading-5">
                            {footerContent.copyrightNotice1}
                        </p>
                        <p className="w-full md:self-end text-micro font-normal md:text-sm md:font-light leading-5">
                            {footerContent.copyrightNotice2}
                        </p>
                    </div>

                </div>
            </div>
        </div>
    );
};
