import { useRef, useState } from 'react';
// @ts-ignore
import emailjs from "@emailjs/browser";

const careerForm = {
    policy: 'By submitting this form I agree to Company Name Privacy Policy and Terms of use.',
    newsletter: 'Please keep me informed about Company Name services, offerings and upcoming events.',
    button: 'Apply now',
    buttonOnLoad: 'Sending...',
    placeholders: {
        name: 'Name and surname',
        email: 'E-mail',
        message: 'Message (link or text)',
        dragAndDropDesktop: 'Drag and drop to add a file',
        dragAndDropMobile: 'Add file',
    },
};

export const CareersForm = () => {
    const [isNewsletterChecked, setIsNewsletterChecked] = useState(false);
    const [isPolicyChecked, setIsPolicyChecked] = useState(false);
    const [fileName, setFileName] = useState<string | null>(null);
    const [fileSize, setFileSize] = useState<number | null>(null);
    const [loading, setLoading] = useState(false);
    const form = useRef<HTMLFormElement>(null);

    const checkNewsletterHandler = () => {
        setIsNewsletterChecked(prevValue => !prevValue);
    }
    const checkPolicyHandler = () => {
        setIsPolicyChecked(prevValue => !prevValue);
    }

    const handleFileChange = (e: any) => {
        if (e.target.files.length) {
            setFileName(e?.target?.files?.[0]?.name);
            setFileSize(e?.target?.files?.[0]?.size);
        } else {
            setFileName(null);
            setFileSize(null);
        }
    }

    const handleDelete = () => {
        (form.current![3] as HTMLInputElement).value = '';
        setFileName(null)
        setFileSize(null)
    }

    const calculateSize = () => {
        if (fileName !== null) {
            const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
            let calculate = fileSize!;
            let i = 0;
            for (i; calculate > 1024; i++) {
                calculate /= 1024
            }
            return Number(calculate.toFixed(1)) + ' ' + units[i];
        }
    }

    const sendEmail = (e: any) => {
        e.preventDefault();
        setLoading(true);
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID!, process.env.REACT_APP_CONTACT_CAREERS_TEMPLATE_ID!, form.current!, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)   
            .then((_result: any) => {
                alert(`Your application has been received. Our team will be in touch with you soon`);
                e.target.reset();
                setIsNewsletterChecked(false);
                setIsPolicyChecked(false);
                setLoading(false)
            }, (_error: any) => {
                alert(`There was some problems. Please try again later.`)
            });
    };

    return (
        <>
            <form ref={form} onSubmit={sendEmail}
                className="flex flex-col max-w-[386px] sm:mt-2 lg:ml-10 xl:ml-20">
                <label
                    htmlFor="from_name"
                    className="sr-only"
                >
                    {careerForm.placeholders.name}
                </label>
                <input
                    type="text"
                    id="from_name"
                    name="from_name"
                    autoComplete={"off"}
                    minLength={3}
                    required={true}
                    placeholder={careerForm.placeholders.name}
                    className="block w-full outline-none appearance-none focus:outline-none border-0 border-b border-b-text py-pcustom7 bg-background font-normal font-sans placeholder:font-sans placeholder:leading-leading272 placeholder:font-normal placeholder:bg-background placeholder:text-text placeholder:opacity-40 md:leading-7"
                />
                <label
                    htmlFor="from_email"
                    className="sr-only"
                >
                    {careerForm.placeholders.email}
                </label>
                <input
                    type="email"
                    name="from_email"
                    id="from_email"
                    autoComplete={"off"}
                    required={true}
                    placeholder={careerForm.placeholders.email}
                    className="mt-5 outline-0 block w-full appearance-none focus:outline-none border-0 border-b border-b-text py-pcustom7 bg-background font-normal font-sans placeholder:font-sans placeholder:font-normal placeholder:leading-leading272 placeholder:bg-background placeholder:text-text placeholder:opacity-40 md:leading-7"
                />
                <label
                    htmlFor="message"
                    className="sr-only"
                >
                    {careerForm.placeholders?.message}
                </label>
                <input
                    type="textarea"
                    id="message"
                    name="message"
                    autoComplete={"off"}
                    placeholder={careerForm.placeholders.message}
                    className="mt-m25 outline-0 focus:outline-none block w-full border-0 border-b border-b-text py-3 bg-background font-normal font-sans placeholder:font-sans placeholder:font-normal placeholder:leading-leading272 placeholder:bg-background placeholder:text-text placeholder:opacity-40 sm:leading-7"
                />
                <label
                    htmlFor="attachment"
                    className="w-1/3 md:w-full md:p-3.5 mt-m30 md:mt-7 mb-3.5 md:border-2 md:border-dashed md:border-backgroundAltDark md:bg-inherit rounded-lg md:opacity-40 cursor-pointer text-center text-font12 font-medium md:leading-leading192 max-h-[80px]"
                >
                    <div>
                        <svg id="magicoon-Bold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                            className="hidden md:block w-6 mx-auto md:mb-2">
                            <title>upload</title>
                            <g id="upload-Bold">
                                <path id="upload-Bold-2" data-name="upload-Bold" className="cls-1"
                                    d="M8.293,7.707a1,1,0,0,1,0-1.414l3-3a1,1,0,0,1,1.414,0l3,3a1,1,0,1,1-1.414,1.414L13,6.414V11a1,1,0,0,1-2,0V6.414L9.707,7.707A1,1,0,0,1,8.293,7.707ZM17,10a1,1,0,0,0,0,2,3,3,0,0,1,3,3v1a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V15a3,3,0,0,1,3-3,1,1,0,0,0,0-2,5.006,5.006,0,0,0-5,5v1a5.006,5.006,0,0,0,5,5H17a5.006,5.006,0,0,0,5-5V15A5.006,5.006,0,0,0,17,10Zm1,5a1,1,0,1,0-1,1A1,1,0,0,0,18,15Z" />
                            </g>
                        </svg>
                    </div>
                    <p className="hidden md:block">{careerForm.placeholders.dragAndDropDesktop}</p>
                    <p
                        className="md:hidden text-whitespace bg-text rounded-lg py-3 px-5 text-font16 max-w-[102px] max-h-[45px]">{careerForm.placeholders?.dragAndDropMobile}</p>
                </label>
                {fileName !== null ? (
                    <div
                        className="text-xs text-text -mt-2 -mb-2 font-medium flex w-full max-w-full overflow-hidden leading-leading192"
                    >
                        <p>{fileName}</p>
                        <p className="mx-1">({calculateSize()})</p>
                        <div className="cursor-pointer" onClick={() => handleDelete()}>
                            <svg id="magicoon-Bold" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                className="w-[18px] h-[18px]">
                                <title>times-square</title>
                                <g id="times-square-Bold">
                                    <path id="times-square-Bold-2" data-name="times-square-Bold" className="cls-1"
                                        d="M15.707,9.707,13.414,12l2.293,2.293a1,1,0,1,1-1.414,1.414L12,13.414,9.707,15.707a1,1,0,0,1-1.414-1.414L10.586,12,8.293,9.707A1,1,0,0,1,9.707,8.293L12,10.586l2.293-2.293a1,1,0,0,1,1.414,1.414ZM22,9v6a7.008,7.008,0,0,1-7,7H9a7.008,7.008,0,0,1-7-7V9A7.008,7.008,0,0,1,9,2h6A7.008,7.008,0,0,1,22,9ZM20,9a5.006,5.006,0,0,0-5-5H9A5.006,5.006,0,0,0,4,9v6a5.006,5.006,0,0,0,5,5h6a5.006,5.006,0,0,0,5-5Z" />
                                </g>
                            </svg>
                        </div>
                    </div>
                ) : null}
                <div className='flex gap-2'>
                    <input
                        checked={isNewsletterChecked}
                        onChange={checkNewsletterHandler}
                        type="checkbox"
                        id="newsletter"
                        name="newsletter"
                        className="
                    relative peer shrink-0
                    appearance-none w-4 h-4 border border-text rounded bg-background
                    mt-4
                    "
                    />
                    <label
                        htmlFor="newsletter"
                        className="text-font14 font-light leading-leading224 tracking-small2 pt-3.5"
                    >
                        {careerForm.newsletter}
                    </label>
                    <svg
                        className="
                    absolute 
                    w-3 h-3 mt-m18 ml-0.5
                    hidden peer-checked:block
                    pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                </div>
                <div className='flex gap-2'>
                    <input
                        checked={isPolicyChecked}
                        onChange={checkPolicyHandler}
                        type="checkbox"
                        id="policy"
                        name="policy"
                        className="
                    relative peer shrink-0
                    appearance-none w-4 h-4 border border-text rounded bg-background
                    mt-2.5
                    "
                    />
                    <label
                        htmlFor="policy"
                        className="text-font14 font-light leading-leading224 tracking-small2 pt-2"
                    >
                        {careerForm.policy}
                    </label>
                    <svg
                        className="
                    absolute 
                    w-3 h-3 mt-3 ml-0.5
                    hidden peer-checked:block
                    pointer-events-none"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                </div>
                <input type="text" name="careers" value="careers" className='hidden' />
                <button type="submit"
                    className="w-full max-w-[386px] mx-auto sm:w-1/2 lg:w-full bg-text text-whitespace rounded-xl py-3.5 mt-7 md:mt-m34 text-font22 font-semibold leading-leading286 duration-500 hover:bg-active">
                    {loading ?
                        (
                            <p className="cursor-not-allowed">{careerForm.buttonOnLoad}</p>
                        ) : (
                            <p>{careerForm.button}</p>
                        )}
                </button>
            </form>
            <input
                type="file"
                id="attachment"
                name="attachment"
                onChange={handleFileChange}
                className="hidden w-full sm:p-6 sm:my-7 border-2 border-dashed border-backgroundAltDark rounded-lg opacity-40"
            />
        </>
    );
};