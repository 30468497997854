import { ContactForm } from "../Home/ContactFrom";

const contactContent = {
    header: 'Book a personalized 1:1',
    mainText: 'Let us know how we can meet and surpass your unique needs and expectations. You will be connected to an expert that will answer your questions and discuss the details of our partnership.',
};

export const Contact = () => {
    return (
        <div
            className="max-w-mobile sm:max-w-off mx-auto grid sm:grid-cols-2 pt-10 sm:pt-pcustom120 pb-10 sm:pb-pcustom106 sm:gap-16 px-5 xl:px-0">
            <div className="">
                <h2
                    className="font-serif text-5xl md:text-technologies font-semibold leading-leading624 md:leading-leading675">
                    {contactContent.header}
                </h2>
                <p className="text-font16 leading-leading272 tracking-small pt-5 pb-10 sm:pt-7 sm:pb-0 sm:w-4/6">
                    {contactContent.mainText}
                </p>
            </div>
            <ContactForm />
        </div>
    );
};
