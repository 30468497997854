import { Disclosure } from "@headlessui/react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { BrandLogo } from "./BrandLogo";
import { DemoButton } from "./DemoButton";
import { NavLink, useNavigate } from "react-router-dom";
import cn from "classnames";

const navLinks = [
    {
        title: 'Our Vision',
        path: '/our-vision'
    },
    {
        title: 'Blog',
        path: '/blog'
    },
    {
        title: 'FAQ',
        path: '/faq'
    },
    {
        title: 'Careers',
        path: '/careers'
    },
];


export const Nav = () => {
    const navigate = useNavigate();

    return (
        <div className="sticky top-0 z-20 w-full bg-background touch-none">
            <Disclosure
                as="nav"
                className="pt-4 pb-3 px-5 md:pt-pcustom25 md:pb-5 max-w-off mx-auto xl:px-0"
            >
                {({ open }) => {
                    return <>
                        <div className="mx-auto">
                            <div className="flex">
                                <div className="flex justify-between w-full h-[45px]">
                                    <BrandLogo />
                                    <div className="flex justify-between md:w-2/3 md:w-4/6">
                                        <div
                                            className="hidden md:flex justify-between w-2/3 ml-10 pr-6 text-font18 text-text leading-leading234 font-normal">
                                            {navLinks.map((link) => {
                                                return (
                                                    <NavLink
                                                        key={link.title}
                                                        to={link.path}
                                                        className={({ isActive }) => cn(
                                                            'hover:text-active duration-500 inline-flex items-center',
                                                            {'text-active': isActive}
                                                        )}
                                                    >
                                                        {link.title}
                                                    </NavLink>
                                                )
                                            })}
                                        </div>
                                        {!open && (<DemoButton content={'Book a DEMO'} />)}
                                    </div>
                                </div>

                                <div className="flex md:hidden">
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        className="rounded-md ml-4 text-text">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XMarkIcon
                                                className="block h-8 w-8"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <svg
                                                id="magicoon-Bold"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                className="block h-9 w-9 -mt-1"
                                            >
                                                <title>menu</title>
                                                <g id="menu-Bold">
                                                    <path id="menu-Bold-2" data-name="menu-Bold" className="cls-1"
                                                        d="M2,5A1,1,0,0,1,3,4H16a1,1,0,0,1,0,2H3A1,1,0,0,1,2,5Zm19,6H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm-9,7H3a1,1,0,0,0,0,2h9a1,1,0,0,0,0-2Z" />
                                                </g>
                                            </svg>
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>
                        {/*mobile*/}
                        <Disclosure.Panel
                            className="md:hidden"
                        >
                            <div
                                className="pt-pcustom105 text-center absolute z-10 bg-background h-[calc(100vh-3rem)] w-full left-0 grid grid-cols-1"
                            >
                                <div>
                                    {navLinks?.map((link, index) => {
                                        return (
                                            <Disclosure.Button
                                                key={index}
                                                as={'a'}
                                                className="block w-1/2 text-lg mx-auto text-text leading-leading234 font-normal pb-pcustom34 cursor-pointer"
                                                onClick={() => {
                                                    navigate(link.path);
                                                }}
                                            >
                                                {link.title}
                                            </Disclosure.Button>
                                        )
                                    })}
                                    <Disclosure.Button
                                        as={'a'}
                                        className="block w-1/2 text-lg mx-auto text-text leading-leading234 font-normal pb-pcustom34 cursor-pointer"
                                        onClick={() => {
                                            navigate('/privacy-policy');
                                        }}
                                    >
                                        Privacy Policy
                                    </Disclosure.Button>
                                    <div className="pt-9 pb-pcustom120 ">
                                        <Disclosure.Button
                                            as={'a'}
                                            onClick={() => {
                                                navigate('/contact');
                                            }}
                                            className="bg-text rounded-lg md:w-fit w-full text-center text-white py-3.5 px-6 text-font22 font-semibold leading-leading286"
                                        >
                                            Book a demo
                                        </Disclosure.Button>
                                    </div>
                                </div>
                                <div className="pt-6 pb-9 text-font10 self-end border-t border-t-text">
                                    <p>More Metaverse© all rights reserved</p>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                }}
            </Disclosure>
        </div>
    );
};