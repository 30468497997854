// import nftWallet from '../assets/images/main/NFT_wallet.jpg'
// import noGas from '../assets/images/main/No_gass.jpg'
// import creditCard from '../assets/images/main/Credit_cards.jpg'
// import customWeb from '../assets/images/main/custom_website.jpg'
import nftWallet from '../assets/newImages/spotlightTechnology/1. Digital_Extentionsv2.jpg'
import noGas from '../assets/newImages/spotlightTechnology/2. Digital_Art_Walletv2.jpg'
import creditCard from '../assets/newImages/spotlightTechnology/3Framex590.jpg'
import customWeb from '../assets/newImages/spotlightTechnology/4. Credit_cards.jpg'
import { Button } from '../shared/Button';

const technologies = [
    {
        header: 'Digital extensions of the finest art pieces.',
        content: 'Offer unique digital extensions of your collection’s art pieces. Yearly adoption or forever ownership. Enable collecting digitalised versions of the finest art pieces.',
        image: <img src={nftWallet} alt="NFT Wallet"
            className="w-full aspect-[5/3] self-center max-h-full rounded-2xl drop-shadow-boxshadow" />,
        button: 'Let\'s talk',
    },
    {
        header: 'Blockchain Digital Art Wallet. Email only.',
        content: 'State-of-the-art security and immediate account recovery. 2FA enabled. Only email address needed.',
        image: <img src={noGas} alt="no gas fees"
            className="w-full aspect-[5/3] self-center object-scale-down rounded-2xl drop-shadow-boxshadow" />,
        button: 'Let\'s talk',
    },
    {
        header: 'Dedicated framed 4K display',
        content: 'As a part of your purchase you receive a Dedicated framed 4K display. It effortlessly blends in and displays your new art piece.',
        image: <img src={creditCard} alt="credit card payments"
            className="w-full aspect-[5/3] self-center object-cover rounded-2xl drop-shadow-boxshadow" />,
        button: 'Let\'s talk',
    },
    {
        header: 'Credit Card Payments',
        content: 'Collectors pay in their currency of choice using credit/debit cards or Apple/Google Pay. Sellers receive their payouts to their bank account. It’s that simple.',
        image: <img src={customWeb} alt="custom website"
            className="w-full aspect-[5/3] self-center  object-cover rounded-2xl drop-shadow-boxshadow" />,
        button: 'Let\'s talk',
    },
];


export const Technology = () => {
    return (
        <div
            className="h-full w-full overflow-hidden items-center max-w-off mx-auto">
            {technologies.map((technology, index) => {
                return (
                    <div key={index}
                        className='grid md:grid-cols-2 items-center border-b border-b-text last:border-b-0 px-5 md:px-0 md:py-20 pt-10 pb-11 md:max-w-screen-2xl md:mx-0'>
                        <div className="text-left order-last md:order-first">
                            <h2
                                className="font-semibold font-serif text-text md:max-w-[490px] text-technologiesMobile  md:text-technologies md:leading-leading675 md:py-7 pt-7 pb-6">
                                {technology.header}
                            </h2>
                            <p
                                className="text-base font-sans font-normal leading-leading272 tracking-small md:max-w-[490px] md:pr-10 md:mb-7">
                                {technology.content}
                            </p>
                            <div className="mt-6 md:mb-2 md:mb-0 flex items-center ">
                                <Button content={technology?.button} />
                            </div>
                        </div>
                        <div
                            className="bg-background p-5 rounded-3xl flex justify-center md:px-10 md:py-pcustom53">
                                {technology.image}
                        </div>
                    </div>
                )
            })}
        </div>
    );
};