import { BlogSection } from "./BlogSection"
import { BuyAndSell } from "./BuyAndSell"
import { Contact } from "../shared/Contact"
import { SummaryInfo } from "./SummaryInfo"
import { Technology } from "./Technology"
import { useTitle } from "react-use"

export const Home = () => {
    useTitle('More Metaverse');

    return (
        <div className="flex min-h-screen flex-col justify-between">
            <BuyAndSell />
            <div className="bg-whitespace w-full">
                <header className="pt-pcustom76 md:pt-pcustom146">
                    <h2
                        className='relative z-10 font-serif text-5xl md:text-header font-semibold leading-leading624 md:leading-leading957 pb-pcustom30 md:pb-10 w-4/5 md:w-3/5 mx-auto text-center max-w-[996px]'
                    >
                        Powering the future of digital art.
                        <span
                            className="absolute right-0 -top-4 md:-right-10 md:-top-10 -z-10 w-120 h-120 md:w-40 md:h-40 rounded-full bg-gradient-to-b from-background "
                        />
                    </h2>
                </header>
                <section className="grid grid-cols-1 place-items-center">
                    <Technology />
                </section>
                <section>
                    <SummaryInfo />
                </section>
                <section>
                    <BlogSection />
                </section>
                <section className="bg-background">
                    <Contact />
                </section>
            </div>
        </div>
    );
};