import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { BlogPost } from "../Home/BlogPost";
import { CategoryFilter } from "./CategoryFilter";
import { DateFilter } from "./DateFilter";
import { BlogPostType } from "./BlogImportantPosts";
import { GetBlogposts } from "../queries/get-blogposts";
import { useQuery } from "@apollo/client";
import { uniq } from "lodash";
import { Pagination } from "./Pagination";

const content = {
    header: 'Sort by:',
    // categories: {
    //     id: 'category',
    //     header: 'Category',
    //     options: [
    //         { value: 'cat1', label: 'Category 1' },
    //         { value: 'cat2', label: 'Category 2' },
    //         { value: 'cat3', label: 'Category 3' },
    //         { value: 'cat4', label: 'Category 4' },

    //     ],
    // },
    placeholder: 'Search',
}

export const BlogPosts = () => {
    const [inputSearch, setInputSearch] = useState('');
    const [selectCategory, setSelectCategory] = useState<string | null>(null);
    const [sortSelect, setSortSelect] = useState<'created_on_DESC' | 'created_on_ASC'>('created_on_DESC');
    const [page, setPage] = useState(1);
    const { loading, error, data } = useQuery<{ BlogPosts: { items: BlogPostType[] } }>(GetBlogposts);

    const onPageChange = (page: number) => {
        setPage(page);
    }

    const handleInputSearch = (e: any) => {
        setInputSearch(e.target.value)
    }

    const handleSortSelect = (option: 'created_on_DESC' | 'created_on_ASC') => {
        setSortSelect(option);
    }

    const handleFilteredSelect = (category: string | null) => {
        setSelectCategory(category)
    }

    const PAGE_SIZE = 3;

    const categories = data ? uniq(data?.BlogPosts.items.map((item) => item.category)) : [];

    let blogPosts: BlogPostType[] = [];
    if (data) {
        let filteredPosts: BlogPostType[];
        if (!selectCategory && !inputSearch) {
            blogPosts = data.BlogPosts.items.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
        } else {
            if (selectCategory && inputSearch) {
                filteredPosts = data.BlogPosts.items.filter(post =>
                    post.category === selectCategory && post.title.toLocaleLowerCase().includes(inputSearch.toLocaleLowerCase()));
            } else if (selectCategory) {
                filteredPosts = data.BlogPosts.items.filter(post => post.category === selectCategory);
            } else if (inputSearch) {
                filteredPosts = data.BlogPosts.items.filter(post => post.title.toLocaleLowerCase().includes(inputSearch.toLocaleLowerCase()));
            }
            blogPosts = filteredPosts!.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);
        }
        if (sortSelect === 'created_on_ASC') {
            blogPosts = blogPosts.reverse();
        }
    }

    if (!data) {
        return <p></p>;
    }

    return (
        <div className="w-full border-t border-t-text">
            <div
                className="border-b border-b-text lg:flex grid grid-cols-1 sm:py-3 sm:px-0 py-3.5 px-5 md:px-0 w-full justify-between">
                <div className="sm:flex grid grid-cols-1 w-10/12">
                    <p className="font-semibold leading-leading272 tracking-small2 sm:w-[15%]">{content.header}</p>
                    <div className="flex justify-between sm:w-1/3 lg:w-1/4 w-4/5 mb-4 mt-3 sm:mb-0 sm:mt-0">
                        <CategoryFilter categories={categories} handleFilteredSelect={handleFilteredSelect} />
                        <DateFilter handleSortSelect={handleSortSelect} />
                    </div>
                </div>
                <div className="flex flex-1 w-full mr-2 max-w-[180px]">
                    <label htmlFor="search" className="sr-only">
                        {content.placeholder}
                    </label>
                    <div className="relative w-11/12 md:w-full ">
                        <div className="pointer-events-none absolute inset-y-0 right-1 md:-right-2 flex items-center pl-3">
                            <MagnifyingGlassIcon className="h-5 w-5 text-text" aria-hidden="true" />
                        </div>
                        <input
                            id="search"
                            name="search"
                            autoComplete={"off"}
                            onChange={(e) => handleInputSearch(e)}
                            className="w-5/6 md:w-full appearance-none bg-transparent border-none md:py-1 md:px-3.5 leading-tight focus:outline-none placeholder:text-text"
                            placeholder={content.placeholder}
                            type="search"
                        />
                    </div>
                </div>
            </div>
            <div
                className="px-5 md:px-0 pt-pcustom30 md:pt-10 md:pb-16 grid grid-cols-2 md:grid-cols-3 gap-4 gap-y-[30px] md:gap-5 justify-items-center">
                {blogPosts?.map((post, index) => {
                    return (
                        <BlogPost key={index} blogPost={post} />
                    )
                })}
            </div>
            <div className="mx-auto text-center py-pcustom50 md:pt-0 md:pb-pcustom110">
                <Pagination
                    pageSize={PAGE_SIZE}
                    itemsLength={data.BlogPosts.items.length}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    );
};
