import { Link } from "react-router-dom";
import { Carousel } from "../shared/Carousel";
import { useQuery } from "@apollo/client";
import { GetBlogposts } from "../queries/get-blogposts";

export type BlogPostType = {
    _slug: string;
    title: string;
    category: string;
    cover_image: {
        url: string;
    };
    content: {
        body: string;
        text: string;
    }[];
};

export const BlogImportantPosts = () => {
    const { loading, error, data } = useQuery<{ BlogPosts: { items: BlogPostType[] } }>(GetBlogposts);

    const blogPosts = [data?.BlogPosts.items[1], data?.BlogPosts.items[2]];

    return (
        <div>
            <section className="sm:hidden my-10 flex">
                <Carousel>
                    {blogPosts.map((post, index) => (
                        <Link key={index} to={`/blog/${post?._slug}`}>
                            <img
                                src={post?.cover_image !== null ? post?.cover_image?.url : "https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                                alt={post?.title}
                                className="aspect-[4/3] rounded-2xl md:rounded-3xl bg-gray-100 object-cover" />
                            <div className="pt-5 md:pt-7">
                                <div
                                    className="pb-3 text-sm leading-leading182">{post?.title}</div>
                                <h2
                                    className="font-serif max-w-full font-semibold text-technologiesMobile leading-leading364 hover:text-active">{post?.category}</h2>
                            </div>
                        </Link>
                    ))}
                </Carousel>
            </section>
            <section className='hidden h-full sm:inline-grid grid-cols-2 mt-10 sm:mb-m60 w-full gap-5 hover:cursor-pointer'>
                {blogPosts.map((post, index) => (
                    <Link key={index} to={`/blog/${post?._slug}`}>
                        <div>
                            <img
                                src={post?.cover_image !== null ? post?.cover_image?.url : "https://s3-alpha-sig.figma.com/img/8070/2729/00982b6ccd13c2e853cfeb0a35536d5a?Expires=1686528000&Signature=WYZeX04uPlIJQ~EssKLSrZP1k8zDjL0rn8VLMOLnWhQRXESP-2wdMbaMf~pUIdBUjZrWin7d3ZRM615iykn8CsgrToKiKV~Xn8-4Zif7P71Eu~sDmXOHqRRnlE2QRXE0SRHGt9XNIRaZeFjLRsPq4Ycv0lY2lLCcAIjulUYaF4YYCM7vK~9Fu8az6QOLWHQ406CipDPeAT2FQgUb4k8ziqFGhKSgbNgB-f~8eh35yWiwiGOfQJq3nxdrat5V8~rRfkUIdNeFWPTEc7di3z3E9EYoFPoFDVQcPvHu50aQxtxNcT37Dpd71e41mM~fJHHqc~GO4t4QnQaW9BPbIM0LAQ__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"}
                                alt={post?.title}
                                className="aspect-[4/3] w-full rounded-3xl bg-gray-100 object-cover md:aspect-[4/3] " />
                            <div className="pt-5 sm:pt-7">
                                <div
                                    className="pb-3 text-sm md:text-base leading-leading182 sm:leading-leading272 md:tracking-small">{post?.category}</div>
                                <h2
                                    className="font-serif max-w-full font-semibold text-technologiesMobile md:text-font48 leading-leading364 md:leading-leading624 hover:text-active">{post?.title}</h2>
                            </div>
                        </div>
                    </Link>
                ))}
            </section>
        </div>
    );
};